/**
 * Interview component
 *
 * Description: Provides an interview interface to record audio answers to questions.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-19
 */

/* Question storyline (previously)
 * General about Platform Economy
 * (1) Could you describe your experience with platform economy concepts in the context of urban mobility and autonomous vehicles? How did these experiences shape your perspective?
 * (2) How do you see the role of different stakeholders (e.g., city planners, regulators, tech developers) in shaping autonomous mobility platforms?
 * (3) What are the key challenges you have encountered in the design and implementation of autonomous mobility solutions? Can you elaborate on specific cases and why they were challenging?
 * (4) From your perspective, what are the major benefits of autonomous mobility services in urban areas, and how do these benefits align with current trends?
 * (5) How do you foresee platform-based architectures influencing future urban mobility systems, and what are some potential impacts on industry and society?
 * ---
 * Platform Economy
 * (6) In your experience, what factors contribute most significantly to a successful platform economy model in mobility? Are there any industry-specific challenges?
 * (7) What technical advancements are most needed to support seamless integration between different mobility services on a platform?
 * (8) How important is data sharing between entities in an autonomous mobility platform, and what challenges do you foresee?
 * (9) What regulatory challenges do you anticipate as autonomous mobility platforms are adopted more widely? Are there any specific policies you think should be prioritized?
 * (10) In your opinion, how will platform economy models evolve in the next 5-10 years, especially in the context of autonomous mobility?
 * ---
 * UCM Modeling Approach
 * (11) How clear and intuitive did you find the use case modeling process using the UCM method for autonomous mobility? Could you provide examples of any difficulties or moments of clarity?How clear and intuitive did you find the use case modeling process using the UCM method for autonomous mobility? Could you provide examples of any difficulties or moments of clarity?
 * (12) Which aspects of the UCM method did you find most challenging or unclear, and why?
 * (13) In your opinion, does the modeling framework effectively represent complex mobility scenarios? Please provide examples or explain any limitations you encountered.
 * (14) Are there specific features or aspects you would add to the UCM modeling framework to better capture the complexities of autonomous mobility scenarios?
 */

export const questions = {
  general_knowledge: {
    q1: {
      question_id: 1,
      question_index: 0,
      questionSet: 1,
      setQuestion: 1,
      question: 'How do you see the role of different stakeholders (e.g., city planners, regulators, tech developers) in shaping autonomous mobility solutions and platforms?',
      question_de: 'Wie sehen Sie die Rolle verschiedener Interessengruppen (z. B. Stadtplaner, Regulierungsbehörden, Technologieentwickler) bei der Gestaltung autonomer Mobilitätsplattformen?',
      question_type: 'open',
      goal: 'Gain an understanding of expert views on collaboration and conflict among key stakeholders in developing autonomous mobility platforms.',
      field: ['Urban Planning', 'Transport Planning', 'Digital Transformation', 'Business Analysis'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '', // Duration in seconds or formatted 'HH:mm:ss'
      audio_transcription: '', // Raw text of the transcription (target language is English)
      audio_detected_original_language: '', // Support for multilingual
      audio_original_text: '', // original text that is translated to English
      transcription_confidence: null, // Confidence level from 0 to 1 if transcribed by AI
      summary: '', // Summary of the transcription
      theme: '', // Theme or topic of the transcription
      keywords: [], // Keywords extracted from the transcription        
      contextual_tags: [], // Tags like 'business', 'technical', 'policy', 'ethical'
      sentiment: '', // Captures sentiment (positive, neutral, negative)
      sentiment_score: null, // Confidence or score of the sentiment analysis  
      response_length: null, // Length of the response in terms of word count or character count        
      answer_quality: '', // "High", "Medium", "Low" based on relevance to the question      
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '', // ID or name of the participant
    },
    q2: {
      question_id: 2,
      question_index: 1,
      questionSet: 1,
      setQuestion: 2,
      question: 'What are the key challenges you have encountered in the design and implementation of autonomous mobility solutions? Can you elaborate on specific cases and why they were challenging?',
      question_de: 'Was sind die wichtigsten Herausforderungen, die Sie bei der Gestaltung und Implementierung autonomer Mobilitätsdienste erlebt haben? Können Sie spezifische Fälle erläutern und erklären, warum sie herausfordernd waren?',
      question_type: 'open',
      field: ['Engineer', 'Autonomous Driving Expert', 'AI/ML Specialist'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    /*
    q4: {
      question_id: 4,
      question_index: 3,
      question: 'From your perspective, what are the major benefits of autonomous mobility services in urban areas, and how do these benefits align with current trends?',
      question_de: 'Aus Ihrer Sicht, was sind die größten Vorteile autonomer Mobilitätsdienste in städtischen Gebieten, und wie passen diese Vorteile zu aktuellen Trends?',
      question_type: 'open',
      field: ['Urban Planning', 'Transport Planning', 'Human Factors', 'Economics'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    q5: {
      question_id: 5,
      question_index: 4,
      question: 'How do you foresee platform-based architectures influencing future urban mobility systems, and what are some potential impacts on industry and society?',
      question_de: 'Wie sehen Sie die zukünftige Rolle plattformbasierter Architekturen in städtischen Mobilitätssystemen, und welche potenziellen Auswirkungen haben sie auf die Industrie und Gesellschaft?',
      question_type: 'open',
      field: ['Business Analysis', 'Innovation Manager', 'Economics', 'Urban Planning'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    */
  },
  platform_economy: {
    /*
    q3: {
      question_id: 3,
      question_index: 2,
      question: 'In your experience, what factors contribute most significantly to a successful platform economy in mobility? Are there any industry-specific challenges?',
      question_de: 'Welche Faktoren tragen Ihrer Erfahrung nach am meisten zu einem erfolgreichen Plattformökonomiemodell in der Mobilität bei? Gibt es branchenspezifische Herausforderungen?',
      question_type: 'open',
      goal: 'Uncover insights into the critical elements that drive success in platform-based models and challenges unique to mobility.',
      field: ['Business Analysis', 'Digital Transformation', 'Economics', 'Urban Planning', 'Autonomous Driving Expert'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    */
    q3: {
      question_id: 3,
      question_index: 2,
      questionSet: 2,
      setQuestion: 1,
      question: 'What technical advancements are most needed to support seamless integration between different autonomous mobility solutions on a platform?',
      question_de: 'Welche technischen Fortschritte sind am dringendsten erforderlich, um eine nahtlose Integration zwischen verschiedenen Mobilitätsdiensten auf einer Plattform zu unterstützen?',
      question_type: 'open',
      goal: 'Identify technological gaps that need to be addressed for a cohesive mobility platform.',
      field: ['Business Analysis', 'Digital Transformation', 'Economics', 'Urban Planning', 'Autonomous Driving Expert'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    q4: {
      question_id: 4,
      question_index: 3,
      questionSet: 2,
      setQuestion: 2,
      question: 'How important is data sharing (data spaces) between actors to build autonomous mobility solutions / ecosystems, and what challenges do you foresee?',
      question_de: 'Wie wichtig ist der Datenaustausch (Datenräume) zwischen Akteuren, um autonome Mobilitätslösungen/-ökosysteme aufzubauen, und welche Herausforderungen sehen Sie?',
      question_type: 'open',
      goal: 'Examine expert perspectives on data-sharing needs, privacy issues, and security in platform ecosystems.',
      field: ['Business Analysis', 'Digital Transformation', 'Economics', 'Urban Planning', 'Autonomous Driving Expert'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    /*
    q9: {
      question_id: 9,
      question_index: 8,
      question: 'What regulatory challenges do you anticipate as autonomous mobility platforms are adopted more widely? Are there any specific policies you think should be prioritized?',
      question_de: 'Mit welchen regulatorischen Herausforderungen rechnen Sie, wenn autonome Mobilitätsplattformen breiter eingeführt werden? Gibt es spezifische Richtlinien, die Ihrer Meinung nach Priorität haben sollten?',
      question_type: 'open',
      goal: 'Uncover expected regulatory obstacles and policies that could support platform-based autonomous mobility.',
      field: ['Business Analysis', 'Digital Transformation', 'Economics', 'Urban Planning', 'Autonomous Driving Expert'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    */
    q5: {
      question_id: 5,
      question_index: 4,
      questionSet: 2,
      setQuestion: 3,
      question: 'In your opinion, how will platform ecosystems evolve in the next 5-10 years, particularly in the context of autonomous mobility?',
      question_de: 'Wie werden Plattformökosysteme Ihrer Meinung nach in den nächsten 5-10 Jahren, insbesondere im Kontext autonomer Mobilität, auftreten?',
      question_type: 'open',
      goal: 'Gather predictions on the future of platform models in mobility to help contextualize long-term goals.',
      field: ['Business Analysis', 'Digital Transformation', 'Economics', 'Urban Planning', 'Autonomous Driving Expert'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
  },
  modeling_approach: {
    /*
    q11: {
      question_id: 11,
      question_index: 10,
      question: 'How clear and intuitive did you find the use case modeling process using the UCM method for autonomous mobility? Could you provide examples of any difficulties or moments of clarity?',
      question_de: 'Wie klar und intuitiv fanden Sie den Anwendungsfallmodellierungsprozess mit der UCM-Methode für autonome Mobilität? Können Sie Beispiele für Schwierigkeiten oder Klarheitsmomente geben?',
      question_type: 'open',
      field: ['Engineer', 'Researcher', 'Project Manager'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    q12: {
      question_id: 12,
      question_index: 11,
      question: 'Which aspects of the UCM method did you find most challenging or unclear, and why?',
      question_de: 'Welche Aspekte der UCM-Methode fanden Sie am herausforderndsten oder unklarsten, und warum?',
      question_type: 'open',
      field: ['Data Scientist', 'Engineer', 'Consultant'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    */
    q6: {
      question_id: 6,
      question_index: 5,
      questionSet: 3,
      setQuestion: 1,
      question: 'In your opinion, does the UCM modeling framework effectively represent complex mobility use cases? Please provide examples or explain any limitations you encountered.',
      question_de: 'Halten Sie das UCM Modellierungsframework für effektiv, um komplexe Mobilitäts-Use Cases darzustellen? Bitte geben Sie Beispiele oder erklären Sie Einschränkungen, die Sie festgestellt haben.',
      question_type: 'open',
      field: ['Transport Planning', 'Urban Planning', 'AI/ML Specialist', 'Autonomous Driving Expert'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
    q7: {
      question_id: 7,
      question_index: 6,
      questionSet: 3,
      setQuestion: 2,
      question: 'Are there specific features or aspects you would add to the UCM modeling framework to better capture the complexities of autonomous mobility use cases?',
      question_de: 'Gibt es spezifische Funktionen oder Aspekte, die Sie dem UCM-Modellierungsframework hinzufügen würden, um die Komplexität autonomer Mobilitäts-Use Cases besser zu erfassen?',
      question_type: 'open',
      goal: 'Get feedback on potential improvements to the UCM modeling framework from an expert perspective.',
      field: ['Business Analysis', 'Digital Transformation', 'Economics', 'Urban Planning', 'Autonomous Driving Expert'],
      recording: false,
      audioData: null,
      audio_file_size: null,
      audio_file_type: 'audio/mp3',
      audio_time_start: '',
      audio_time_end: '',
      audio_duration: '',
      audio_transcription: '',
      audio_detected_original_language: '',
      audio_original_text: '',
      transcription_confidence: null,
      summary: '',
      theme: '',
      keywords: [],
      contextual_tags: [],
      sentiment: '',
      sentiment_score: null,
      response_length: null,
      answer_quality: '',
      findings: [],
      main_argument: '',
      quotes: [],
      response_relevance: [],
      anonymous_id: '',
    },
  },
};
