/**
 * Modal to manage a model.
 * 
 * Description: This component is a modal that allows the user to manage a model, including updating and deleting it.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-11-22
 * 
 */

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Card, Dropdown } from 'react-bootstrap';

import { UserInformation } from '../auth_mgmt/auth_handling';

// Modal indicating read-only view for the Modal Manage Semantic Model if no user is logged in
const ModalManageSemModelReadOnly = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton style={{ backgroundColor: '#f8f9fa' }}>
                <Modal.Title>
                    <i className="bi bi-info-circle"></i> Important note
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '30px' }}>
                <p>
                    <b><i className="bi bi-lock"></i> Semi-Access Mode for External Users</b>
                </p>
                <p>
                    You are exploring the application as a non-registered user. This module was made available for research purposes only and as part of a submitted paper.
                    <br /><br />
                    The semantic model mapper currently accepts models in the RDF/XML format. Tools like Protege may support in the creation of these models.
                    <br /><br />
                    Semantic models can not be manipulated, a login would be required.
                    This is only granted upon request to ensure the integrity of the data.
                </p>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: '#f8f9fa' }}>
                <Button variant="outline-secondary" onClick={onHide}>
                    <i className='bi bi-x-lg' style={{ marginRight: '5px' }}></i>
                    Noted
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const ModalManageModel = ({ show, onHide, models, enrichmentOptions, onUpdate, onDelete }) => {
    const [selectedModel, setSelectedModel] = useState(null);
    const [updatedModel, setUpdatedModel] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isReadOnlyModal, setIsReadOnlyModal] = useState(false);

    // Check if the user is logged in
    useEffect(() => {

        if (!UserInformation()) {
            setIsReadOnly(true);
            setIsReadOnlyModal(true);
        }

    }, []);

    // Handle selection of a model to edit
    const handleSelectModel = (model) => {
        setSelectedModel(model);
        setUpdatedModel({ ...model }); // Initialize editable fields
    };

    // Handle updates to input fields
    const handleInputChange = (field, value) => {
        setUpdatedModel((prev) => ({ ...prev, [field]: value }));
    };

    const handleInputChangeMapping = (field, value) => {
        setUpdatedModel((prevModel) => ({
            ...prevModel,
            [field]: value,
        }));
    };

    // Handle updating a model
    const handleUpdate = () => {
        if (!updatedModel.modelName || !updatedModel.acronym || !updatedModel.ontologyURL) {
            alert('Please fill in all mandatory fields.');
            return;
        }
        onUpdate(updatedModel);
        setSelectedModel(null); // Reset after update
        onHide();
    };

    // Handle deletion of a model
    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this model?')) {
            onDelete(selectedModel.id);
            setSelectedModel(null); // Reset after delete
            onHide();
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Manage Semantic Models</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!selectedModel ? (
                    <>
                        {/* Search and select a model */}
                        <Form.Control
                            type="text"
                            placeholder="Search for a model..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                                marginBottom: '20px',
                                borderRadius: '5px',
                                padding: '10px',
                                fontSize: '0.9rem',
                                border: '1px solid #ccc',
                            }}
                        />
                        <Row>
                            {models
                                .filter((model) =>
                                    model.modelName
                                        ?.toLowerCase()
                                        ?.includes(searchTerm.toLowerCase())
                                )
                                .map((model, index) => (
                                    <Col key={index} md={4} style={{ marginBottom: '15px' }}>
                                        <Card
                                            className='h-100'
                                            style={{
                                                cursor: 'pointer',
                                                border: '1px solid #ddd',
                                                borderRadius: '8px',
                                                boxShadow:
                                                    '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                padding: '10px',
                                                transition: 'all 0.3s ease-in-out',
                                            }}
                                            onClick={() => handleSelectModel(model)}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.05)';
                                                e.currentTarget.style.boxShadow =
                                                    '0 6px 12px rgba(0, 123, 255, 0.2)';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.transform = 'scale(1)';
                                                e.currentTarget.style.boxShadow =
                                                    '0 2px 4px rgba(0, 0, 0, 0.1)';
                                            }}
                                        >
                                            <Card.Body>
                                                <Card.Title
                                                    style={{
                                                        fontSize: '0.95rem',
                                                        fontWeight: 'bold',
                                                        color: '#333',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {model.modelName}
                                                </Card.Title>
                                                <Card.Text style={{ fontSize: '0.8rem', color: '#555' }}>
                                                    <strong>Acronym:</strong> {model.acronym || 'N/A'}
                                                </Card.Text>
                                                <Card.Text style={{ fontSize: '0.8rem', color: '#555' }}>
                                                    <strong>Ontology URL:</strong>{' '}
                                                    <span
                                                        style={{
                                                            color: '#007bff',
                                                            wordBreak: 'break-all',
                                                        }}
                                                    >
                                                        {model.ontologyURL || 'Not provided'}
                                                    </span>
                                                </Card.Text>
                                                <Card.Text
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        color: '#555',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    title={model.description || 'No description available'}
                                                >
                                                    <strong>Description:</strong>{' '}
                                                    {model.description || 'No description available'}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    </>
                ) : (
                    <>
                        {/* Edit the selected model */}
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Model Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={updatedModel.modelName}
                                    onChange={(e) =>
                                        handleInputChange('modelName', e.target.value)
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Acronym*</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={updatedModel.acronym}
                                    onChange={(e) => handleInputChange('acronym', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Ontology URL*</Form.Label>
                                <Form.Control
                                    type="url"
                                    value={updatedModel.ontologyURL}
                                    onChange={(e) =>
                                        handleInputChange('ontologyURL', e.target.value)
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                            <Form.Label>Enrichment / Mapping*</Form.Label>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary" id="dropdown-basic" style={{ width: '100%' }}>
                                    {updatedModel.mapping ? (
                                        <div>
                                            <strong>{updatedModel.mapping.label}</strong>
                                            <div style={{ fontSize: 'small', color: 'gray' }}>
                                                {updatedModel.mapping.description || 'No description available'}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="text-start">
                                            <small>
                                                Select Initial Mapping from Taxonomy to UCM Component Architecture
                                                <br />
                                                Default mapping is UCB; this can be changed anytime
                                            </small>
                                        </div>
                                    )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto', width: '100%' }}>
                                    {enrichmentOptions?.map((option, idx) => (
                                        <Dropdown.Item
                                            key={idx}
                                            onClick={() => handleInputChangeMapping('mapping', option)} // Correctly update the mapping field
                                            style={{ padding: '10px' }}
                                        >
                                            <div>
                                                <strong>{option.label}</strong>
                                                <div style={{ fontSize: 'small', color: 'gray' }}>
                                                    {option.description || 'No description available'}
                                                </div>
                                                <ul style={{ paddingLeft: '20px', marginTop: '5px', color: '#555' }}>
                                                    {Object.keys(option.structure || {}).map((key, index) => (
                                                        <li key={index}>
                                                            <strong>{key}:</strong> {option.structure[key]}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={updatedModel.description}
                                    onChange={(e) =>
                                        handleInputChange('description', e.target.value)
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Version</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={updatedModel.version}
                                    onChange={(e) => handleInputChange('version', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>License</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={updatedModel.license}
                                    onChange={(e) => handleInputChange('license', e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>

                <Button variant="outline-secondary" onClick={onHide}>
                    <i className='bi bi-x-lg' style={{ marginRight: '5px' }}></i>
                    Cancel
                </Button>

                {selectedModel && (
                    <Button variant="outline-danger" onClick={handleDelete} disabled={isReadOnly}>
                        <i className='bi bi-trash' style={{ marginRight: '5px' }}></i>
                        Delete
                    </Button>
                )}                
                {selectedModel && (
                    <Button variant="outline-primary" onClick={handleUpdate} disabled={isReadOnly}>
                        <i className='bi bi-save' style={{ marginRight: '5px' }}></i>
                        Update
                    </Button>
                )}
            </Modal.Footer>

            {selectedModel && isReadOnly && (
                <ModalManageSemModelReadOnly show={isReadOnlyModal} onHide={() => setIsReadOnlyModal(false)} />
            )}

        </Modal>
    );
};


export default ModalManageModel;