/**
 * Modal to add / upload a new ontology / taxonomy (xml /  owl / rdf) file to the knowledge representation view
 * 
 * Description: This component is a modal that allows the user to add a new ontology / taxonomy file to the knowledge representation view.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-11-20
 * 
 */

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, Row, Col, Dropdown } from 'react-bootstrap';

import { UserInformation } from '../auth_mgmt/auth_handling';

// Modal indicating read-only view for the Modal Add Semantic Model if no user is logged in
const ModalAddSemModelReadOnly = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton style={{ backgroundColor: '#f8f9fa' }}>
                <Modal.Title>
                    <i className="bi bi-info-circle"></i> Important note
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '30px' }}>
                <p>
                    <b><i className="bi bi-lock"></i> Semi-Access Mode for External Users</b>
                </p>
                <p>
                    You are exploring the application as a non-registered user. This module was made available for research purposes only and as part of a submitted paper.
                    <br /><br />
                    The semantic model mapper currently accepts models in the RDF/XML format. Tools like Protege may support in the creation of these models.
                    <br /><br />
                    All added models in this semi-access mode will not be stored to the database. 
                    In order to further manipulate, add and store new semantic models, a login would be required. 
                    This is only granted upon request to ensure the integrity of the data.
                </p>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: '#f8f9fa' }}>
                <Button variant="outline-secondary" onClick={onHide}>
                    <i className='bi bi-x-lg' style={{ marginRight: '5px' }}></i>
                    Noted
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const ModalAddSemModelModel = ({ show, onHide, onAddModel, enrichmentOptions }) => {

    const [isReadOnly, setIsReadOnly] = useState(false);

    const [modelName, setModelName] = useState('');
    const [acronym, setAcronym] = useState('');
    const [ontologyURL, setOntologyURL] = useState('');
    const [description, setDescription] = useState('');
    const [version, setVersion] = useState('');
    const added_by = UserInformation()?.displayName || 'N/A';
    const [author, setAuthor] = useState('');
    const [license, setLicense] = useState('');
    const [mapping, setMapping] = useState(null);

    // Check if the user is logged in
    useEffect(() => {

        if (!UserInformation()) {
            setIsReadOnly(true);
        }

    }, []);


    const isValidURL = (url) => {
        try {
            new URL(url);
            return true;
        } catch {
            return false;
        }
    };

    const handleAdd = () => {
        if (!modelName || !acronym || !ontologyURL || !description || !mapping) {
            alert('Please fill in all mandatory fields (marked with *).');
            return;
        }

        if (!isValidURL(ontologyURL)) {
            alert('Please enter a valid URL.');
            return;
        }

        const newModel = {
            modelName,
            acronym,
            ontologyURL,
            description,
            version,
            author,
            added_by,
            license,
            mapping,
        };

        onAddModel(newModel);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton style={{ backgroundColor: '#f8f9fa' }}>
                <Modal.Title>Add New Semantic Model</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '30px' }}>
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Model Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter the model name"
                                    value={modelName}
                                    onChange={(e) => setModelName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Acronym*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Model acronym"
                                    value={acronym}
                                    onChange={(e) => setAcronym(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Label>Ontology URL*</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="url"
                                placeholder="https://example.com/ontology.owl"
                                value={ontologyURL}
                                onChange={(e) => setOntologyURL(e.target.value)}
                                isInvalid={ontologyURL && !isValidURL(ontologyURL)}
                            />
                            {ontologyURL && !isValidURL(ontologyURL) && (
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid URL.
                                </Form.Control.Feedback>
                            )}
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Enrichment / Mapping*</Form.Label>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic" style={{ width: '100%' }}>
                                {mapping ? (
                                    <div>
                                        <strong>{mapping.label}</strong>
                                        <div style={{ fontSize: 'small', color: 'gray' }}>
                                            {mapping.description || 'No description available'}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-start">
                                    <small>
                                        Select Initial Mapping from Taxonomy to UCM Component Architecture 
                                        <br/>
                                        Default mapping is UCB; this can be changed anytime
                                    </small>
                                    </div>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto', width: '100%' }}>
                                {enrichmentOptions?.map((option, idx) => (
                                    <Dropdown.Item
                                        key={idx}
                                        onClick={() => setMapping(option)}
                                        style={{ padding: '10px' }}
                                    >
                                        <div>
                                            <strong>{option.label}</strong>
                                            <div style={{ fontSize: 'small', color: 'gray' }}>
                                                {option.description || 'No description available'}
                                            </div>
                                            <ul style={{ paddingLeft: '20px', marginTop: '5px', color: '#555' }}>
                                                {Object.keys(option.structure || {}).map((key, index) => (
                                                    <li key={index}>
                                                        <strong>{key}:</strong> {option.structure[key]}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description*</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Describe the purpose of this model"
                            rows={3}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Version</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="e.g., 1.0.0"
                            value={version}
                            onChange={(e) => setVersion(e.target.value)}
                        />
                    </Form.Group>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Author</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter author name"
                                    value={author}
                                    onChange={(e) => setAuthor(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Added by</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={added_by}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Label>License</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Enter license information"
                            rows={3}
                            value={license}
                            onChange={(e) => setLicense(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: '#f8f9fa' }}>
                <Button variant="outline-secondary" onClick={onHide}>
                    <i className='bi bi-x-lg' style={{ marginRight: '5px' }}></i>
                    Cancel
                </Button>
                <Button 
                    variant="outline-primary" 
                    onClick={handleAdd}
                    disabled={!modelName || !acronym || !ontologyURL || !description || !mapping } // || !isReadOnly
                >
                    <i className='bi bi-plus-lg' style={{ marginRight: '5px' }}></i>
                    Add Model
                </Button>
            </Modal.Footer>

            {isReadOnly && <ModalAddSemModelReadOnly show={isReadOnly} onHide={() => setIsReadOnly(false)} />}

        </Modal>
    );
};

export default ModalAddSemModelModel;



