/**
 * Knowledge Representation View
 * 
 * Description: This view provides a visualization of the knowledge representation
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-09-20; adopted 2024-07-06
 * 
 */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Col, Form, Button, OverlayTrigger, Tooltip, Alert, Modal } from 'react-bootstrap';
import { OWLVisualization } from '../onto_handling/onto_vis';
import MatchingModule from '../onto_handling/onto_matching';

import { useNavigate } from 'react-router-dom';

// Read and write ontology data
import { fetchOntologyData } from '../db_mgmt/db_handling_ontologies/db_ontology_read';
import { saveOntologyData, updateOntologyData, deleteOntologyData } from '../db_mgmt/db_handling_ontologies/db_ontology_write';

import { OWLFileParser } from '../onto_handling/onto_parse';
import { buildSidebarItems } from '../onto_tree_handling/parse_items';
import { expandAllItems, collapseAllItems } from '../onto_tree_handling/expansion';
import { renderConceptBadges } from '../component_handling/render_concepts';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { UserInformation } from '../auth_mgmt/auth_handling';

// Select owl / taxonomy
import ModalSelectOntology from './knwl_select_model_modal';
// Add new owl / taxonomy
import ModalAddSemModelModel from '../onto_handling/onto_modal_add_model'
// Manage owl / taxonomy
import ModalManageModel from '../knowledge_handling/knwl_manage_model_modal';

//import { PEAMONTOontologyURL, GUCOontologyURL, BaselineOntologyURL } from '../onto_handling/onto_config';
import { 
    comp_semantic_enrichment_guco_enhanced, 
    comp_semantic_enrichment_baseline_enhanced, 
    comp_semantic_enrichment_peamonto_enhanced,
    comp_semantic_enrichment_goki_enhanced,
    comp_semantic_enrichment_energy_enhanced,
    comp_semantic_enrichment_health_enhanced,
    comp_semantic_enrichment_security_enhanced
    
} from '../knowledge_handling/knwl_enrichment_structure';

// Create Mode for use cases
import ModalCreateMode from '../create_handling/create_modal_mode';

// Modal providing the information that model has temporarily loaded for use
const TmpLoadedModel = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton style={{ backgroundColor: '#f8f9fa' }}>
                <Modal.Title>
                    <i className="bi bi-rocket-takeoff"></i> Model loaded successfully 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '30px' }}>
                <p>
                    <b><i className="bi bi-info-circle"></i> Semi-Access Mode for External Users</b>
                </p>
                <p>
                    Your model has now been successfully loaded. Please keep in mind that it has not been stored to the database.
                </p>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: '#f8f9fa' }}>
                <Button variant="outline-secondary" onClick={onHide}>
                    Noted
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default function KnwlDashboard () {

    // Add new semantic model
    const [showModalAddModel, setShowModalAddModel] = useState(false);
    const [models, setModels] = useState([]);
    const [showTmpLoaded, setShowTmpLoaded] = useState(false);

    // Manage semantic model
    const [showModalManageModel, setShowModalManageModel] = useState(false);

    // Select ontology / taxonomy
    const [showModalSelectOntology, setShowModalSelectOntology] = useState(false);
    const [searchTerm, setSearchTerm] = useState('Information');

    // Define the state variable for the selected model
    const [sem_model, setSemModel] = useState('GUCO');
    const [ontologyURL, setOntologyURL] = useState('');

    // Enrichment
    const [enrichment, setEnrichment] = useState(comp_semantic_enrichment_guco_enhanced);
    const [enrichmentOptions, setEnrichmentOptions] = useState();
    const [isEditing, setIsEditing] = useState(false);

    const [isReadOnly, setIsReadOnly] = useState(false);

    // Check if the user is logged in
    useEffect(() => {

        if (!UserInformation()) {
            setIsReadOnly(true);
        }

    }, []);

    // Predefined order for the matching module
    /*
    const predefinedOrder = [
        { section: true, name: 'Operations' },
        'Operations Management',
        'Operations Analytics',
        'Operations Platform',
        { section: true, name: 'Producer' },
        'Producer Analytics',
        'Producer Management',
        'Producer Platform',
        { section: true, name: 'Consumer' },
        'Consumer Analytics',
        'Consumer Delivery',
        'Consumer Platform',
        { section: true, name: 'Technical Layer' },
        'Applications',
        'Platform',
        'Control',
        'AI-Middleware',
        'Hardware',
        { section: true, name: 'Regulator' },
        'Directives',
        'Reference Cases',
        'Standards',
    ];
    */

    //const [isDynamicModel, setIsDynamicModel] = useState(false);
    
    // semantic model enrichment descriptions
    const descr_guco = 'Maps selected GUCO concepts to the UCM component architecture';
    const descr_ucb = 'Maps selected Use Case Baseline concepts to the UCM component architecture';
    const descr_peamonto = 'Maps selected PEAMONTO concepts to the UCM component architecture';
    const descr_goki = 'Maps selected GOKI concepts to the UCM component architecture';
    const descr_energy = 'Maps selected Energy concepts to the UCM component architecture';
    const descr_health = 'Maps selected Health concepts to the UCM component architecture';
    const descr_sec = 'Maps selected Security concepts to the UCM component architecture';

    // RDF handling and sidebar items
    const [sidebarItems, setSidebarItems] = useState([]); 

    const isKnowledgeSite = true;
    const networkRef = useRef(null);
    const [currentLayout, setCurrentLayout] = useState('circle');

    const [retrieveOWL, setRetrieveOWL] = useState(false);
    const [match, setMatch] = useState(false);

    // sidebar items
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSearch, setIsSearch] = useState(false);   

    // Modal for Create Mode
    const [showModalCreateMode, setShowModalCreateMode] = useState(false);

    // Error handling
    const [error, setError] = useState('');
    
    // --- Semantic Model Handling ---

    // Fetch ontology data / semantic models from the database
    /*
    useEffect(() => {
        fetchOntologyData().then((data) => {
            setModels(data);
            if (data.length > 0) {
                // Automatically set the first model as default
                setSemModel(data[0].acronym);
                setOntologyURL(data[0].ontologyURL);
                setEnrichment(data[0].mapping.enrichment || comp_semantic_enrichment_guco_enhanced); // Ensure enrichment is fetched
            }
        });
    }, []);
    */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchOntologyData();
                setModels(data);
    
                if (data.length > 0) {
                    // Automatically set the first model as default
                    setSemModel(data[0].acronym);
                    setOntologyURL(data[0].ontologyURL);
                    setEnrichment(data[0].mapping.enrichment || comp_semantic_enrichment_guco_enhanced);
                }
            } catch (error) {
                console.error('Failed to fetch ontology data:', error);
                setError('You do not have permission to access this data. Please contact your administrator.');
            }
        };
    
        fetchData();
    }, []);

    // Handle model selection
    const handleSelectModel = (selectedModel) => {
        setSemModel(selectedModel.acronym); // Update selected semantic model
        setOntologyURL(selectedModel.ontologyURL); // Update ontology URL
        setEnrichment(selectedModel.mapping.enrichment || null); // Update enrichment if provided
        setShowModalSelectOntology(false); // Close modal
        //setIsIntegrated(selectedModel.isIntegrated || false); // Update integration flag

        // handle dynamic models
        /*
        if (selectedModel.acronym !== 'GUCO') {
            setIsDynamicModel(true);
        } else {
            setIsDynamicModel(false)
        }
        */

        // Set init search term depending on the selected model
        switch (selectedModel.acronym) {
            case 'GUCO':
                setSearchTerm('Information');
                break;
            case 'UCB':
                setSearchTerm('Components');
                break;
            case 'PEAMONTO':
                setSearchTerm('Autonomous Mobility (AM)');
                break;  
            case 'GOKI':
                setSearchTerm('Concepts');
                break;          
            default:
                setSearchTerm('');
        }
    };
    
    const rdfData = OWLFileParser(ontologyURL, useState, useEffect);

    // parse the RDF data and build the sidebar items
    // used in the component modeling step and the chat to enhance the data by semantic information
    useEffect(() => {
        if (rdfData !== null) {
            const items = buildSidebarItems(rdfData);
            if (items.length > 0) {
                items[0].collapsed = false;
                //setSemModel(items[0].title);
            }
            setSidebarItems(items);
        }
    }, [rdfData]); // setSemModel

    // Initialize the enrichment options
    useEffect(() => {
        const options = [
            { label: 'Use Case Baseline', value: 'UCB', description: descr_ucb, enrichment: comp_semantic_enrichment_baseline_enhanced },
            { label: 'Generic Use Case Ontology', value: 'GUCO', description: descr_guco, enrichment: comp_semantic_enrichment_guco_enhanced },            
            { label: 'Platform Economy Autonomous Mobility Ontology', value: 'PEAMONTO', description: descr_peamonto, enrichment: comp_semantic_enrichment_peamonto_enhanced },
            { label: 'Common Good AI Ontology', value:'GOKI', description: descr_goki, enrichment: comp_semantic_enrichment_goki_enhanced },
            { label: 'Energy Ontology', value: 'Energy', description: descr_energy, enrichment: comp_semantic_enrichment_energy_enhanced },
            { label: 'Health Ontology', value: 'Health', description: descr_health, enrichment: comp_semantic_enrichment_health_enhanced },
            { label: 'Security Ontology', value: 'Security', description: descr_sec, enrichment: comp_semantic_enrichment_security_enhanced },
        ];
        setEnrichmentOptions(options);
    }, []);

    // Edit / Save enrichment
    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    // Add new semantic model and store it in the database
    const handleAddModel = (newModel) => {
        //console.log('Adding new model: ', newModel);

        // Add and store new model in the database (read only mode not given)
        if (!error) {

            // only save model to db if user is authentificated / known
            if (isReadOnly) {
                setShowTmpLoaded(true)
            } else {
                saveOntologyData(newModel); // Save the model to the database
            }

            setModels((prev) => [...prev, newModel]); // Update local state
            setSemModel(newModel.acronym); // Automatically select the new model

            setOntologyURL(newModel.ontologyURL); // Set its URL
            setEnrichment(newModel.mapping.enrichment || null); // Set its enrichment data
        } else {
            alert('No permissions granted to add a model; please sign up to add a semantic model.');
        }
    };

    // Update a semantic model
    const handleUpdateModel = (updatedModel) => {
        setModels((prevModels) =>
            prevModels.map((model) =>
                model.id === updatedModel.id ? updatedModel : model
            )
        );

        // update the database
        updateOntologyData(updatedModel);

        alert('Model updated successfully.');
    };
    
    // Delete a semantic model
    const handleDeleteModel = (modelId) => {

        //console.log('Deleting model with ID: ', modelId);       
        //console.log('Models before deletion: ', models); 

        setModels((prevModels) => prevModels.filter((model) => model.id !== modelId));
        //console.log('Models after deletion: ', models);

        // delete from the database
        deleteOntologyData(modelId);

        alert('Model deleted successfully.');
    };

    // --- Network Visualization and main toolbar ---

    const layoutOptions = useMemo(() => ({
        normal: {
            physics: {
                enabled: true,
                solver: 'forceAtlas2Based',
                forceAtlas2Based: {
                    gravitationalConstant: -50,
                    centralGravity: 0.01,
                    springLength: 100,
                    springConstant: 0.08,
                    damping: 0.4,
                    avoidOverlap: 1,
                },
                stabilization: {
                    iterations: 200,
                },
            },
        },
        hierarchical: {
            layout: {
                hierarchical: {
                    enabled: true,
                    levelSeparation: 150,
                    nodeSpacing: 100,
                    treeSpacing: 200,
                    blockShifting: true,
                    edgeMinimization: true,
                    parentCentralization: true,
                    direction: 'UD', // UD, DU, LR, RL
                    sortMethod: 'hubsize', // hubsize, directed
                    shakeTowards: 'leaves' // roots, leaves
                },
            },
            physics: {
                enabled: false,
            },
        },
        grid: {
            layout: {
                improvedLayout: false,
            },
            physics: {
                enabled: false,
            },
        },
        circular: {
            layout: {
                improvedLayout: false,
                hierarchical: false,
            },
            physics: {
                enabled: false,
            },
        },
    }), []);

    const changeLayout = (layout) => {
        setCurrentLayout(layout);
        networkRef.current.setOptions(layoutOptions[layout]);
    };

    const handleZoomIn = () => {
        if (networkRef.current) {
            const scale = networkRef.current.getScale();
            networkRef.current.moveTo({
                scale: scale * 1.2, // Zoom in
            });
        }
    };

    const handleZoomOut = () => {
        if (networkRef.current) {
            const scale = networkRef.current.getScale();
            networkRef.current.moveTo({
                scale: scale * 0.8, // Zoom out
            });
        }
    };

    const handleRetrieveOWL = () => {
        setRetrieveOWL(!retrieveOWL);
    }

    const handleMatch = () => {
        setMatch(!match);
    }

    // --- Sidebar items ---

    // Sidebar items
    const expandAll = () => {
        const updatedSidebarItems = expandAllItems(sidebarItems);
        setSidebarItems(updatedSidebarItems);
        setIsExpanded(true);
    };

    const collapseAll = () => {
        const updatedSidebarItems = collapseAllItems(sidebarItems);
        updatedSidebarItems[0].collapsed = false;
        setSidebarItems(updatedSidebarItems);
        setIsExpanded(false);
    }

    // Handle change event
    const handleModelChange = (event) => {
        setSemModel(event.target.value);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // --- Add use case, repository ---

    // Create Mode
    const handleCreateMode = () => {
        setShowModalCreateMode(true);
    };

    const navigate = useNavigate();
  
    // repository, dashboard
    const navigateRepository = () => {
      navigate('/Repository');
    };

    return (
        <div className="text-center" style={ { marginTop:'30px'} }>          
            {/* Error handling */}
            {error && (
                <>
                    <div style={{ marginTop: '20px' }}>
                        <Alert variant="danger">{error}</Alert>
                    </div>
                </>
            )}
            <DndProvider backend={HTML5Backend}>            
                <Row style={{ marginBottom:'15px'}}>
                    <Col md={1}></Col>
                    {/* Headline and Toolbar */}
                    <Col md={3}>
                        <div>
                            <h3 className="text-start">Semantic Model Details</h3>
                            <h6 className="text-start">Look up model</h6>                      
                        </div>
                    </Col>
                    {/* Toolbar */}
                    <Col md={7}>
                        {/* Row 1: Model-related actions */}
                        <Row className="mb-3">
                            <Col md={8} className="d-flex align-items-center">
                                {/* Semantic Model Selection: Open modal to select ontology */}
                                <OverlayTrigger placement="top" overlay={<Tooltip>Select a semantic model</Tooltip>}>
                                <Button
                                    variant="outline-primary"
                                    onClick={() => setShowModalSelectOntology(true)}
                                    style={{ marginRight: '10px' }}
                                >
                                    <i className="bi bi-database"></i> Select Taxonomy
                                </Button>
                                </OverlayTrigger>
                                {/* Labek of current selected model */}
                                <Form.Label className="text-start" style={{ marginTop: '10px' }}>
                                    Selected Model: <strong>{sem_model}</strong>
                                </Form.Label>

                            </Col>
                            <Col md={4} className="d-flex justify-content-end align-items-center">
                                {/* Upload and Add new semantic model */}
                                <OverlayTrigger placement="top" overlay={<Tooltip>Upload a new semantic model</Tooltip>}>
                                    <Button variant="outline-secondary" onClick={() => setShowModalAddModel(true)}>
                                        <i className="bi bi-plus"></i> Add
                                    </Button>
                                </OverlayTrigger>

                                {/* Manage semantic model (update or delete if admin or the who added it) */}
                                <OverlayTrigger placement="top" overlay={<Tooltip>Manage semantic model</Tooltip>}>
                                    <Button
                                        variant="outline-secondary"
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => setShowModalManageModel(true)}
                                    >
                                        <i className="bi bi-gear"></i> Manage
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>

                        {/* Row 2: Visualization and layout tools */}
                        <Row>
                            <Col md={8} className="d-flex align-items-center">
                                {!match && (
                                    <>
                                        {/* Zoom Controls */}
                                        <Button onClick={handleZoomIn} variant="outline-secondary" style={{ marginRight: '10px' }}>
                                            <i className="bi bi-zoom-in"></i>
                                        </Button>
                                        <Button onClick={handleZoomOut} variant="outline-secondary" style={{ marginRight: '10px' }}>
                                            <i className="bi bi-zoom-out"></i>
                                        </Button>
                                    </>
                                )}

                                {!match && (
                                    <>
                                        {/* Layout Buttons */}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Circle View</Tooltip>}>
                                            <Button
                                                variant={currentLayout === 'circle' ? 'primary' : 'outline-secondary'}
                                                style={{ marginRight: '10px' }}
                                                onClick={() => changeLayout('circle')}
                                            >
                                                <i className="bi bi-circle"></i>
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Hierarchy View</Tooltip>}>
                                            <Button
                                                variant={currentLayout === 'hierarchical' ? 'primary' : 'outline-secondary'}
                                                style={{ marginRight: '10px' }}
                                                onClick={() => changeLayout('hierarchical')}
                                            >
                                                <i className="bi bi-diagram-3"></i>
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Star View</Tooltip>}>
                                            <Button
                                                variant={currentLayout === 'normal' ? 'primary' : 'outline-secondary'}
                                                style={{ marginRight: '10px' }}
                                                onClick={() => changeLayout('normal')}
                                            >
                                                <i className="bi bi-asterisk"></i>
                                            </Button>
                                        </OverlayTrigger>

                                        {/* Search */}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Search for a class or concept</Tooltip>}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search concept/class"
                                                style={{ maxWidth: '200px', marginRight: '10px' }}
                                                value={searchTerm}
                                                onChange={handleSearchTermChange}
                                            />
                                        </OverlayTrigger>
                                    </>
                                )}
                            </Col>
                            <Col md={4} className="d-flex justify-content-end align-items-center">
                                {match && (
                                    <>
                                        {/* Edit / Save enrichment */}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit assignment</Tooltip>}>
                                        <Button variant="outline-secondary" onClick={toggleEditMode} style={{ marginRight: '10px' }} disabled>
                                            {isEditing ? 
                                                <span><i className="bi bi-pencil-fill"></i> Save</span> : 
                                                <span><i className="bi bi-pencil"></i> Edit</span>
                                            }
                                        </Button>
                                        </OverlayTrigger>
                                        {/* Visualize Semantic Model */}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Visualize the selected semantic model</Tooltip>}>
                                            <Button variant="outline-secondary" onClick={handleMatch}>
                                                <i className="bi bi-diagram-3"></i> Model
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                )}

                                {!match && (
                                    <>
                                        {/* Match Semantic Model */}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Match semantic model</Tooltip>}>
                                            <Button variant="outline-secondary" onClick={handleMatch} style={{ marginRight: '10px' }}>
                                                <i className="bi bi-magic"></i> Match
                                            </Button>
                                        </OverlayTrigger>

                                        {/* Retrieve OWL */}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Retrieve OWL model data</Tooltip>}>
                                            <Button variant="outline-secondary" onClick={handleRetrieveOWL}>
                                                <i className="bi bi-code"></i> OWL
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Col>

                    <Col md={1}></Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    {/* Sidebar */}
                    <Col md={3} style={{ paddingRight: '30px', backgroundBlendMode: 'gray' }}> {/* borderRight: '0.5px solid #ccc', */}
                        <hr />
                        <div className="sidebar">
                            {/* Toolbar */}
                            <Row>
                                <Col md={12}>
                                {/* Select semantic model */}        
                                <div className="sidebar">
                                    {rdfData ? (
                                    <>
                                    <Row style={ {marginBottom:'15px'}}>
                                        <h6 className="text-start">{sem_model} Elements | Classes & Concepts</h6>
                                        <div className="text-start">
                                            {/* Expand / Collapse all */}
                                            {isExpanded ? (
                                                <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={collapseAll}>
                                                    <i className="bi bi-arrows-collapse"></i> Collapse All
                                                </Button>                                    
                                            ) : (
                                                <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={expandAll}>
                                                <i className="bi bi-arrows-expand"></i> Expand All
                                                </Button>
                                            )}              
                                            {/* Change semantic model */}                      
                                            <OverlayTrigger placement="top" overlay={<Tooltip><i className="bi bi-lightbulb"></i> Change semantic model</Tooltip>}>
                                                <Button
                                                    size="sm"
                                                    variant="outline-primary"
                                                    onClick={() => setShowModalSelectOntology(true)}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <i className="bi bi-database"></i> Change
                                                </Button>
                                            </OverlayTrigger>
                                            {/* Search semantic model */}
                                            <OverlayTrigger placement="top" overlay={<Tooltip><i className="bi bi-search"></i> Search class/concept</Tooltip>}>
                                            <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={() => setIsSearch(!isSearch)}>
                                                <i className="bi bi-search"></i> Search
                                            </Button>
                                            </OverlayTrigger>
                                        </div>                                        
                                        {/* Search specific term; auto completion */}
                                        {isSearch && (
                                        <div style={ { marginTop:'15px' } }>
                                            <h6 className="text-start"><i className="bi bi-search"></i> Search Term</h6>
                                            <Form.Control type="text" placeholder="Search concept/class" value={searchTerm} onChange={handleSearchTermChange} />
                                        </div>
                                        )}
                                    </Row>
                                    {/* Concepts */}
                                    <Row className="sidebar" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                        <h6 className="text-start">Hierarchy</h6>
                                        {renderConceptBadges(isKnowledgeSite, sidebarItems, sidebarItems, setSidebarItems, setSearchTerm)}
                                    </Row>
                                    <hr />  
                                    </>
                                    ) : (
                                    <Row className="text-start" style={ {marginBottom:'15px'}}>
                                        <h6>No ontology data available for {sem_model}</h6>
                                    </Row>
                                    )}                          
                                </div>      
                                {/* Move on */}
                                {!isReadOnly && (
                                <div className="text-start" style={ {marginBottom:'15px'}}>                                   
                                    <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={handleCreateMode}>
                                    <i className="bi bi-plus"></i> Model Use Case
                                    </Button>
                                    <Button variant="outline-primary" size="sm" onClick={navigateRepository}>
                                    <i className="bi bi-database"></i> Repository
                                    </Button>                              
                                </div>           
                                )}
                                </Col>                                                     
                            </Row>                         
                        </div>
                    </Col>
                    <Col md={7}>
                    {/* Network Visualization and Code; and Matching Module */}
                    {!match ? (
                        <OWLVisualization 
                            rdfData={rdfData}
                            // Retrieve OWL data
                            retrieveOWL={retrieveOWL}
                            setRetrieveOWL={setRetrieveOWL}
                            // used to propagate whether to use the onClick in this page; different use in actor page
                            isKnowledgeSite={isKnowledgeSite}
                            currentLayout={currentLayout}
                            layoutOptions={layoutOptions}
                            sem_model={sem_model} 
                            setSemModel={setSemModel} 
                            handleModelChange={handleModelChange} 
                            searchTerm={searchTerm} 
                            setSearchTerm={setSearchTerm} 
                            handleSearchTermChange={handleSearchTermChange} 
                            networkRef={networkRef}
                        />
                    ) : (
                        <MatchingModule 
                            enrichment={enrichment}
                            setEnrichment={setEnrichment}
                            isEditing={isEditing}
                            toggleEditMode={toggleEditMode}
                        />
                    )}
                    </Col>
                    <Col md={1}></Col>
                    </Row>
            </DndProvider>

            {/* Modal for Create Mode */}
            {showModalCreateMode && (
                <ModalCreateMode 
                show={showModalCreateMode} 
                onHide={() => setShowModalCreateMode(false)}         
                />  
            )}

            {/* Modal for selecting ontology */}
            {showModalSelectOntology && (
                <ModalSelectOntology
                    show={showModalSelectOntology}
                    onHide={() => setShowModalSelectOntology(false)}
                    modelOptions={models}
                    onSelect={handleSelectModel}
                />
            )}

            {/* Modal for Add Model */}
            {showModalAddModel && (
                <ModalAddSemModelModel 
                    show={showModalAddModel} 
                    onHide={() => setShowModalAddModel(false)}
                    onAddModel={handleAddModel}      
                    enrichmentOptions={enrichmentOptions} 
                />  
            )}

            {/* Modal for temporary loaded model */}
            <TmpLoadedModel 
                show={showTmpLoaded}
                onHide={() => setShowTmpLoaded(false)}
            />

            {/* Modal for Manage Model */}
            {showModalManageModel && (
                <ModalManageModel
                    show={showModalManageModel}
                    onHide={() => setShowModalManageModel(false)}
                    models={models} // Pass the full list of models
                    enrichmentOptions={enrichmentOptions} 
                    onUpdate={(updatedModel) => {
                        // Update the model in the database
                        handleUpdateModel(updatedModel);

                        // Update the state
                        setModels((prev) =>
                            prev.map((model) =>
                                model.id === updatedModel.id ? updatedModel : model
                            )
                        );

                        // If the updated model is the currently selected one, update sem_model
                        if (sem_model === updatedModel.modelName) {
                            setSemModel(updatedModel.modelName);
                        }
                    }}
                    onDelete={(modelId) => {
                        // Delete the model in the database
                        handleDeleteModel(modelId);

                        // Update the state
                        setModels((prev) => prev.filter((model) => model.id !== modelId));

                        // Reset selected model if it's the one being deleted
                        if (models.find((model) => model.id === modelId)?.modelName === sem_model) {
                            setSemModel('');
                        }
                    }}
                />
            )}
        </div>
    );
}

export { default as KnwlDashboard } from './knwl_dashboard';