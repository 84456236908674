/**
 * Survey: Question Set (QS) Intro
 *
 * Description: The QS Intro provides initial questions for the expert to answer
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-13 (updated on Oct 15)
 */

import React from 'react';
import { Row, Form } from 'react-bootstrap';

export const SurveyQSExperience = ( { surveyData, handleChange, currentStep } ) => {
    
    return (
        <>
            {/* Question 4: Overall Experience */}
            {currentStep === 4 && (
                <Row align="center" style={{ maxWidth: '700px', margin: '0 auto' }}>

                    <h3 style={{ marginTop: '30px', marginBottom: '20px' }}>Your overall experience</h3>
                    <p style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '20px' }}>
                        Please provide your feedback on the overall experience of the research and study.
                    </p>

                    {/* Reproducibility */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.userExperience.reproducibility.question}</b></Form.Label>
                        <div style={{ margin: '0 auto', marginTop:'30px', maxWidth:'400px' }}>
                            <Form.Check
                                type="radio"
                                label="Highly reproducible"
                                name="reproducibility"
                                value="Highly reproducible"
                                checked={surveyData.userExperience.reproducibility.answer === 'Highly reproducible'}
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Reproducible"
                                name="reproducibility"
                                value="Reproducible"
                                checked={surveyData.userExperience.reproducibility.answer === 'Reproducible'}
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Somewhat reproducible"
                                name="reproducibility"
                                value="Somewhat reproducible"
                                checked={surveyData.userExperience.reproducibility.answer === 'Somewhat reproducible'}
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Not really reproducible"
                                name="reproducibility"
                                value="Not really reproducible"
                                checked={surveyData.userExperience.reproducibility.answer === 'Not really reproducible'}
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Not reproducible"
                                name="reproducibility"
                                value="Not reproducible"
                                checked={surveyData.userExperience.reproducibility.answer === 'Not reproducible'}
                                onChange={handleChange}
                            />
                        </div>
                    </Form.Group>

                    {/* Overall Experience */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.userExperience.overall_experience.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '700px', margin: '0 auto', marginTop:'30px' }}>
                            <Form.Check
                                type="radio"
                                label="Poor"
                                name="overall_experience"
                                value="Poor"
                                checked={surveyData.userExperience.overall_experience.answer === 'Poor'}
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Fair"
                                name="overall_experience"
                                value="Fair"
                                checked={surveyData.userExperience.overall_experience.answer === 'Fair'}
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Average"
                                name="overall_experience"
                                value="Average"
                                checked={surveyData.userExperience.overall_experience.answer === 'Average'}
                                onChange={handleChange}
                            />                            
                            <Form.Check
                                type="radio"
                                label="Very good"
                                name="overall_experience"
                                value="Very good"
                                checked={surveyData.userExperience.overall_experience.answer === 'Very good'}
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Excellent"
                                name="overall_experience"
                                value="Excellent"
                                checked={surveyData.userExperience.overall_experience.answer === 'Excellent'}
                                onChange={handleChange}
                            />                                                                                    
                        </div>
                    </Form.Group>

                    {/* Additional Comments */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.userExperience.additional_comments.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', maxWidth: '500px' }}>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            name="additional_comments"
                            value={surveyData.userExperience.additional_comments.answer}
                            onChange={handleChange}
                            placeholder="Your feedback here..."
                        />
                        </div>
                    </Form.Group>

                </Row>

            )}
        </>
    );
}