/**
 * Modal Add Component
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: (updated on 2024-10-09)
 * 
 */

import { useState } from 'react';
import { Modal, Button, Form, Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

// Helper function to extract titles from nested structures
const extractTitles = (items, path = []) => {
  let titles = [];
  items.forEach(item => {
      titles.push({ label: item.title, value: [...path, item.title].join(' > ') });
      if (item.subconcepts && item.subconcepts.length > 0) {
          titles = titles.concat(extractTitles(item.subconcepts, [...path, item.title]));
      }
  });
  return titles;
};

const AddComponentModal = ({ show, onHide, onAddComponent, sidebarItems }) => {
  //const [newComponentLabel, setNewComponentLabel] = useState('');
  const [selectionMode, setSelectionMode] = useState('select'); // guided
  const [selectedType, setSelectedType] = useState(null);

  const [selectedPath, setSelectedPath] = useState([]); // Tracks the current path in the hierarchy
  const [currentLevel, setCurrentLevel] = useState(sidebarItems); // Current level of items in the hierarchy

  // Extract titles from sidebarItems
  const options = extractTitles(sidebarItems);

  const handleAddComponent = () => {  

    if (selectedType) { // newComponentLabel && 
        if (selectionMode === 'select') {
          onAddComponent(selectedType.label);
        } else {
          onAddComponent(selectedType);
        }
        //setNewComponentLabel('');
        setSelectedType(null);
        onHide();
    }
  };

  /*
  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          event.preventDefault(); // Prevent form submission
          handleAddComponent();
      }
  };
  */

  const handleSelectCategory = (item) => {
    if (item.subconcepts && item.subconcepts.length > 0) {
        setSelectedPath([...selectedPath, item.title]);
        setCurrentLevel(item.subconcepts || []);
    } else {
        setSelectedPath([...selectedPath, item.title]);
    }    
    setSelectedType(null);
  };

  const handleBack = () => {

    // reset selected type when going back
    setSelectedType(null);

    const updatedPath = selectedPath.slice(0, -1); // Remove the last element
    setSelectedPath(updatedPath);

    let tempLevel = sidebarItems;
    updatedPath.forEach((path) => {
        const selectedItem = tempLevel.find((item) => item.title === path);
        tempLevel = selectedItem.subconcepts || [];
    });
    setCurrentLevel(tempLevel);
  };

  return (
      <Modal show={show} onHide={onHide}>
          <Modal.Header closeButton>
              <Modal.Title>Add New Component Element</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {selectionMode === 'select' && (
                <Form>
                    {/* Input for Component Label 
                    <Form.Group controlId="formNewNodeLabel" style={{ marginBottom: '10px' }}>
                        <Form.Label>Component Label</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter component label"
                            value={newComponentLabel}
                            onChange={(e) => setNewComponentLabel(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </Form.Group>
                    */}
                    
                    {/* Type Select to add a new component */}
                    <Form.Group controlId="formNewNodeLabel" style={{ marginBottom: '10px' }}>
                        <Form.Label>Type to select or create a new component</Form.Label>
                        <CreatableSelect
                            isClearable
                            options={options}
                            value={selectedType}
                            onChange={setSelectedType}
                            placeholder="Select or create a component"
                            formatCreateLabel={(inputValue) => `Create new: "${inputValue}"`}
                        />
                    </Form.Group>

                    {/* Switch to guided select mode, right aligned */}
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-select-mode">Switch to guided selection mode</Tooltip>}
                    >
                    <Button
                        className="btn-sm"
                        variant="outline-secondary"
                        style={{ float: 'right', marginTop:'15px' }}
                        onClick={() => {
                          setSelectionMode('guided')
                          setSelectedType(null)
                        }}
                    >
                        <i className="bi bi-card-list"></i> selection mode                  
                    </Button>
                    </OverlayTrigger>

                </Form>
              )}

              {/* Guided Selection Mode */}
              {selectionMode === 'guided' && (
              <>    
                {/* Card View for Hierarchy */}
                <Row>
                  {selectedPath.length > 0 && (
                    <Col xs={12} style={{ marginBottom: '15px' }} align='right'>
                      <Button className="btn-sm" variant="outline-secondary" onClick={handleBack}>
                        <i className="bi bi-back"></i>  Back
                      </Button>
                    </Col>
                  )}
                  {currentLevel.map((item, index) => (
                    <Col xs={6} md={4} key={index} style={{ marginBottom: '15px' }}>
                      <Card
                        className='h-100'
                        onClick={() => setSelectedType(item.title)} // Select the card
                        style={{
                          cursor: 'pointer',
                          border: selectedType === item.title ? '2px solid #007bff' : '1px solid #ddd',
                          boxShadow: selectedType === item.title ? '0px 0px 10px rgba(0, 123, 255, 0.5)' : 'none',
                        }}
                      >
                        <Card.Body>
                          <Card.Title style={{ fontSize: '0.85rem', textAlign: 'center' }}>{item.title}</Card.Title>
                          {item.subconcepts && item.subconcepts.length > 0 && (
                            <Button
                              variant="link"
                              className="btn-sm"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent parent card click from firing
                                handleSelectCategory(item);
                                setSelectedType(null);
                              }}
                              style={{
                                fontSize: '0.75rem',
                                padding: '0',
                                textDecoration: 'underline',
                                color: '#007bff',
                              }}
                            >
                              <i className="bi bi-arrow-down-circle"></i> Subcategories
                            </Button>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>

                {/* Breadcrumb Navigation */}
                <div style={{ marginBottom: '10px', color:'lightgray' }}>
                  <small>
                  <b>Selected Path: </b>
                  {selectedPath.length > 0 ? (
                    <span>{selectedPath.join(' > ')}</span>
                  ) : (
                    <span>No category selected</span>
                  )}
                  </small>
                </div>         

                {/* Switch back to select mode, right aligned */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-select-mode">Switch to select mode</Tooltip>}
                >
                <Button
                  className="btn-sm"
                  variant="outline-secondary"
                  style={{ float: 'right' }}
                  onClick={() => {
                    setSelectionMode('select')
                    setSelectedType(null)
                  }}
                >
                  <i className="bi bi-arrow-left"></i> select mode
                </Button>
                </OverlayTrigger>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>
                  Cancel
              </Button>
              <Button 
                  variant="primary" 
                  onClick={handleAddComponent} 
                  disabled={!selectedType} 
              >
                  Add Element
              </Button>
          </Modal.Footer>
      </Modal>
  );
};

export default AddComponentModal;