/**
 * Modal to select a model to be added to the knowledge representation view.
 * 
 * Description: This component is a modal that allows the user to select a model to be added to the knowledge representation view.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-11-22
 * 
 */

import React from 'react';
import { Modal, Button, Form, Row, Col, Card } from 'react-bootstrap';

const ModalSelectOntology = ({ show, onHide, modelOptions, onSelect }) => {

    const [searchTerm, setSearchTerm] = React.useState('');

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                    Select Ontology / Taxonomy
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Search Input */}
                <Form.Control
                    type="text"
                    placeholder="Search for an ontology or taxonomy..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                        marginBottom: '20px',
                        borderRadius: '5px',
                        padding: '10px',
                        fontSize: '0.9rem',
                        border: '1px solid #ccc',
                    }}
                />
                <Row>
                    {modelOptions
                        .filter((option) => {
                            const search = searchTerm.toLowerCase();
                            const modelName = (option.modelName || '').toLowerCase();
                            const acronym = (option.acronym || '').toLowerCase();
                            return modelName.includes(search) || acronym.includes(search);
                            })
                        .map((option, index) => (
                            <Col key={index} md={4} style={{ marginBottom: '15px' }}>
                                <Card
                                    className='h-100'
                                    style={{
                                        cursor: 'pointer',
                                        border: '1px solid #ddd',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        padding: '10px',
                                        transition: 'all 0.3s ease-in-out',
                                    }}
                                    onClick={() => onSelect(option)}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.transform = 'scale(1.05)';
                                        e.currentTarget.style.boxShadow =
                                            '0 6px 12px rgba(0, 123, 255, 0.2)';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.transform = 'scale(1)';
                                        e.currentTarget.style.boxShadow =
                                            '0 2px 4px rgba(0, 0, 0, 0.1)';
                                    }}
                                >
                                    <Card.Body>
                                        {/* Title */}
                                        <Card.Title
                                            style={{
                                                fontSize: '0.95rem',
                                                fontWeight: 'bold',
                                                color: '#333',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {option.acronym || 'Unnamed Model'}
                                        </Card.Title>
                                        {/* Information */}
                                        <Card.Text
                                            style={{
                                                fontSize: '0.8rem',
                                                color: '#555',
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <strong>Model Name:</strong>{' '}
                                            {option.modelName || 'Unnamed Model'}
                                        </Card.Text>
                                        <Card.Text
                                            style={{
                                                fontSize: '0.8rem',
                                                color: '#555',
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <strong>Ontology URL:</strong>{' '}
                                            <span
                                                style={{
                                                    color: '#007bff',
                                                    wordBreak: 'break-all',
                                                }}
                                            >
                                                {option.ontologyURL || 'Not provided'}
                                            </span>
                                        </Card.Text>
                                        <Card.Text
                                            style={{
                                                fontSize: '0.8rem',
                                                color: '#555',
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <strong>Mapping:</strong>{' '}
                                            {option.mapping?.value}
                                        </Card.Text>
                                        <Card.Text
                                            style={{
                                                fontSize: '0.8rem',
                                                color: '#555',
                                                marginBottom: '5px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={option.description || 'No description available'}
                                        >
                                            <strong>Description:</strong>{' '}
                                            {option.description || 'No description available'}
                                        </Card.Text>
                                        <Card.Text
                                            style={{
                                                fontSize: '0.8rem',
                                                color: '#555',
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <strong>Author:</strong>{' '}
                                            {option.author || 'Unknown'}
                                        </Card.Text>
                                        <Card.Text
                                            style={{
                                                fontSize: '0.8rem',
                                                color: '#555',
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <strong>Version:</strong>{' '}
                                            {option.version || '1.0'}
                                        </Card.Text>
                                        <Card.Text
                                            style={{
                                                fontSize: '0.8rem',
                                                color: '#555',
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <strong>License:</strong>{' '}
                                            {option.license || 'Unknown'}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide}>
                    <i className='bi bi-x-lg' style={{ marginRight: '5px' }}></i>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalSelectOntology;
