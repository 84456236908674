/**
 * Interview component
 *
 * Description: Provides an interview interface to record audio answers to questions.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-19
 */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { getCurrentDateTime } from '../components/utils/utils_date';

import MicRecorder from 'mic-recorder-to-mp3';
import { transcribeWithWhisper } from '../components/study_handling/study_interview/study_transcription';

import OpenAI from 'openai';
import { getOpenAIKey } from '../components/db_mgmt/db_handling_use_cases/read';

import { useTranscriptionAnalysis, detectOriginalLanguage, translateToEnglish } from '../components/study_handling/study_interview/study_utils_transcribed_answer';

import RecordedAudioTable from '../components/study_handling/study_interview/study_recorded_answers';

import { useInterviewDataHook } from '../components/study_handling/study_data_states/study_state_interview';

import { saveInterviewData } from '../components/db_mgmt/db_handling_interview/db_interview_write';

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../components/auth_mgmt/auth_config'

import '../App.css';

// ######################## Audio Recording and Transcription ########################

const uploadAudioToStorage = async (audioBlob, filename, anonymous_id) => {
  try {
    const storageRef = ref(storage, `interview-audio-${anonymous_id}/${filename}`);
    await uploadBytes(storageRef, audioBlob);
    return getDownloadURL(storageRef); // Returns the download URL after upload
  } catch (error) {
    console.error("Error uploading audio to storage:", error);
    throw error;
  }
};

function getTimeDifferenceInHHMMSS(dateString1, dateString2) {
    // Convert the date strings to Date objects
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);
    
    // Get the difference in time (milliseconds)
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    
    // Convert to seconds, minutes, and hours
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;
    
    // Format the result as hh:mm:ss
    const formattedTime = [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0'),
    ].join(':');

    return formattedTime;
}

function RecordingComponent({ recording }) {
    const [seconds, setSeconds] = useState(0);

    // Start and stop the timer based on the recording state
    useEffect(() => {
        let interval;

        if (recording) {
            // Reset timer at the start of recording
            setSeconds(0);

            // Start counting up every second
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        } else {
            // Stop the timer when recording is false
            clearInterval(interval);
        }

        // Cleanup interval on component unmount or recording state change
        return () => clearInterval(interval);
    }, [recording]);

    // Format seconds as mm:ss
    const formatTime = () => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    return (
        <span style={{ color: 'white', marginLeft:'5px' }}>
            {recording && formatTime()}
        </span>
    );
}

// ######################## Text Input Box ########################

const TextInputBox = ({ textInput, textAnswer, setTextAnswer }) => {
    return (
        <>
            {/* Display the text input for the answer */}
            {textInput && (
                <div style={{ marginTop: '20px', position: 'relative', maxWidth: '600px', margin: '0 auto' }}>

                    {/* Textarea styled as a paper */}
                    <textarea
                        className="styled-textarea"
                        placeholder="Enter your answer here..."
                        value={textAnswer}
                        onChange={(e) => setTextAnswer(e.target.value)}
                        rows={10}
                    ></textarea>
                </div>
            )}
        </>
    );
};

// ######################## Interview Component ########################

/**
 * Page navigation indicator
 * @param {*} param0 
 * @returns 
 */
const PageIndicator = ({ currentStep, totalQuestionSets, currentQuestionIndex, questionKeys, prevQuestion, nextQuestion, recording, textInput, handleSubmit, loadingSubmit, isProcessed, audioData, totalNumberOfQuestions }) => { 

    //console.log('audio data', audioData);
    //console.log('audio data length', audioData.length);
    //console.log('total number of questions', totalNumberOfQuestions);


    return (
        <div>
            <span style={ { color:'gray' } }>
                <b>
                <small>
                    Question Set {currentStep} / {totalQuestionSets} - Question{' '}
                    {currentQuestionIndex + 1}/{questionKeys.length}
                </small>
                </b>
            </span>
            {/* Navigation Buttons - Previous */}
            <Button
                className="btn btn-sm"
                variant="outline-secondary"
                style={{ marginRight: '5px', marginLeft: '10px' }}
                onClick={prevQuestion}
                disabled={
                    (currentStep === 1 && 
                    currentQuestionIndex === 0) ||
                    (recording ||
                    textInput)
                }
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            {/* Navigation Buttons - Next */}
            {!(currentStep === totalQuestionSets && currentQuestionIndex === questionKeys.length-1) && (
            <Button
                className='btn btn-sm'
                variant="primary"
                style={{ marginRight: '5px', marginLeft: '10px' }}
                onClick={nextQuestion}
                disabled={recording || textInput}
            >                        
                {currentQuestionIndex < questionKeys.length - 1 ? 'Next Question' : 'Next Set'}
            </Button>
            )}
            {/* Submit Button */}
            {(currentStep === totalQuestionSets && currentQuestionIndex === questionKeys.length-1) && (
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Submit your answers to complete the interview.</Tooltip>}>
                <Button
                    className='btn btn-sm'
                    variant="success"
                    style={{ marginRight: '5px', marginLeft: '10px' }}
                    onClick={handleSubmit}
                    disabled={audioData.length !== totalNumberOfQuestions}
                >
                {loadingSubmit ? (
                    <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...
                    </>
                ) : (
                    <>
                        Submit
                    </>
                )}
                </Button>
                </OverlayTrigger>
            )}
        </div>
    );
};

const DisplayQuestionStage = ({ currentStep, totalQuestionSets, currentQuestionIndex, questionKeys }) => {

    return (
        <div style={ { color:'gray' } }>
            <span>
                <b>
                <small>
                    Question Set {currentStep} / {totalQuestionSets} - Question{' '}
                    {currentQuestionIndex + 1}/{questionKeys.length}
                </small>
                </b>
            </span>
        </div>
    );
};

export default function Interview( { studyExpertData, setShowModalStudy } ) {

    const [openai, setOpenai] = useState(null);
    const [interviewData, setInterviewData] = useInterviewDataHook();
    
    // audio recording and transcription handling
    const [recording, setRecording] = useState(false);
    const [audioData, setAudioData] = useState([]);
    const [recorder] = useState(new MicRecorder({ bitRate: 128 }));

    // text input handling
    const [textInputToggle, setTextInputToggle] = useState(false);
    const [textInput, setTextInput] = useState(false);
    const [textAnswer, setTextAnswer] = useState('');
    const [loadingTextInput, setLoadingTextInput] = useState(false);
    //const [transcriptions, setTranscriptions] = useState([]);

    // ######################## Data Access and Handling ########################

    const [currentStep, setCurrentStep] = useState(1);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    
    const questionSets = Object.keys(interviewData.questions); // ['general_knowledge', 'modeling_approach', ...]
    const totalQuestionSets = questionSets.length;
    const totalQuestions = questionSets.reduce(
        (total, setKey) => total + Object.keys(interviewData.questions[setKey]).length,
        0
    ); // Total number of questions across all sets

    const currentQuestionSet = interviewData.questions[questionSets[currentStep - 1]];
    const [currentQuestionKey, setCurrentQuestionKey] = useState(Object.keys(currentQuestionSet)[0]);
    const currentQuestionSetLabel = questionSets[currentStep - 1];
    const questionKeys = Object.keys(currentQuestionSet); // Get question keys like ['q1', 'q2']

    const [isProcessed, setIsProcessed] = useState(false);
    const [loading, setLoading] = useState(false);

    const [startTime, setStartTime] = useState(null);

    // loading handling for submitting and storing the audio files in the storage
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    // show the recorded audio table
    const [showRecordedAudioTable, setShowRecordedAudioTable] = useState(false);


    // Initialize the OpenAI API
    useEffect(() => {
        const initializeOpenAI = async () => {
            try {
                const apiKey = await getOpenAIKey();
                const openaiInstance = new OpenAI({
                    apiKey: apiKey,
                    dangerouslyAllowBrowser: true,
                });
                setOpenai(openaiInstance);
            } catch (error) {
                console.error('Error fetching API key:', error);
            }
        };

        initializeOpenAI();
    }, []);

    // Call the transcription analysis hook OUTSIDE of conditional statements
    const {
        targetLangTranscription,
        originalLanguage,
        summary,
        theme,
        keywords,
        contextualTags,
        sentiment,
        sentimentScore,
        answerQuality,
        findings,
        mainArgument,
        quotes,
        relevance,
    } = useTranscriptionAnalysis(
        openai, 
        interviewData.questions[currentQuestionSetLabel]?.[currentQuestionKey]?.audio_original_text,
        setIsProcessed,
        setTextInput,
        setLoadingTextInput,
    );

    // Update the interview data when the transcription analysis is complete
    useEffect(() => {
        if (!isProcessed || !openai ) return; // || !transcriptions[currentQuestionIndex]

        const currentQuestionKey = questionKeys[currentQuestionIndex];
        setCurrentQuestionKey(currentQuestionKey);
        //console.log('current question key:', currentQuestionKey);
        const currentQuestionSet = questionSets[currentStep - 1];
        //console.log('current question set:', currentQuestionSet);

        setInterviewData((prev) => {
            const currentQuestionData = prev.questions[currentQuestionSet][currentQuestionKey];

            const updatedQuestion = {
                //...prev.questions[currentQuestionSet][currentQuestionKey],
                ...currentQuestionData,
                audio_transcription: targetLangTranscription, // Store the transcription text
                audio_detected_original_language: originalLanguage,
                summary: summary,
                theme: theme,
                keywords: keywords,
                contextual_tags: contextualTags,
                sentiment: sentiment,
                sentiment_score: sentimentScore,
                answer_quality: answerQuality,
                findings: findings,
                main_argument: mainArgument,
                quotes: quotes,
                response_relevance: relevance,
            };
            
            // Only update if data has changed (shallow comparison using JSON.stringify for arrays/objects)
            const hasChanged = JSON.stringify(currentQuestionData) !== JSON.stringify(updatedQuestion);

            if (!hasChanged) return prev;
            
            return {
                ...prev,
                questions: {
                    ...prev.questions,
                    [currentQuestionSet]: {
                        ...prev.questions[currentQuestionSet],
                        [currentQuestionKey]: updatedQuestion,
                    },
                },
            };
        });

        // Reset the isProcessed state after the update
        setIsProcessed(false);

        //console.log('Interview data updated:', interviewData);

    }, [
        isProcessed,
        targetLangTranscription,
        originalLanguage,
        summary,
        theme,
        keywords,
        contextualTags,
        sentiment,
        sentimentScore,
        answerQuality,
        findings,
        mainArgument,
        quotes,
        relevance,
        currentQuestionIndex,
        currentStep,
        openai,
        questionKeys,
        questionSets,
        //transcriptions,
        setInterviewData,
    ]);

    // UseEffect to handle static data initialization
    // runs on component mount or when studyExpertData changes
    useEffect(() => {
        if (studyExpertData) {
            setInterviewData((prev) => ({
                ...prev,
                anonymous_id: {
                    ...prev.anonymous_id,
                    value: studyExpertData.anonymous_id || '',
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                mode: {
                    ...prev.mode,
                    value: 'study',
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                role: {
                    ...prev.role,
                    value: studyExpertData.role || '',
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                consent: {
                    ...prev.consent,
                    value: studyExpertData.consentGiven || false,
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                consent_date: {
                    ...prev.consent_date,
                    value: getCurrentDateTime(),
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                place: {
                    ...prev.place,
                    value: studyExpertData.city || '',
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                experience: {
                    ...prev.experience,
                    value: studyExpertData.experience || '',
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                expert_field: {
                    ...prev.expert_field,
                    value: studyExpertData.expertField || '',
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                yearOfBirth: {
                    ...prev.yearOfBirth,
                    value: studyExpertData.yearOfBirth || '',
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                department: {
                    ...prev.department,
                    value: studyExpertData.department || '',
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                study_step: {
                    ...prev.study_step,
                    value: '5',
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                },
                study_step_start: {
                    ...prev.study_step_start,
                    value: getCurrentDateTime(),  // Assuming study step starts now
                    modified: true,
                    anonymous_id: studyExpertData.anonymous_id || '',
                }
            }));
        }
    }, [studyExpertData, setInterviewData]); // , [studyExpertData, setInterviewData]

    // Calculate progress based on the number of answered questions
    const calculateProgress = () => {
        const answeredQuestions = audioData.length; // Number of questions with recorded audio
        return (answeredQuestions / totalQuestions) * 100;
    };

    // Function to calculate the duration of the recording
    const calcDuration = (startTime, endTime) => {

        // Convert to Date objects if not already
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the difference in milliseconds
        const diffMs = Math.abs(end - start);

        // Convert milliseconds to minutes and seconds
        const minutes = Math.floor(diffMs / 60000); // 60000 ms = 1 minute
        const seconds = Math.floor((diffMs % 60000) / 1000); // Remaining ms converted to seconds

        // Format as mm:ss
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    };

    // Function to handle recording and transcription
    const handleRecord = () => {

        if (!recording) {

            // Start recording
            recorder.start().then(() => {

                setStartTime(getCurrentDateTime());
                setRecording(true);

            }).catch((e) => console.error(e));
            
        } else {
            // Stop recording and process the audio
            recorder.stop().getMp3().then(async ([buffer, blob]) => {

                setLoading(true);

                const audioURL = URL.createObjectURL(blob);
                const endTime = getCurrentDateTime();
                const duration = calcDuration(startTime, endTime);
                                
                const currentQuestionKey = questionKeys[currentQuestionIndex]; // Get current question key
                setCurrentQuestionKey(currentQuestionKey);
                //console.log('current question key:', currentQuestionKey);

                // neu:
                //const currentQuestionSet = questionSets[currentStep - 1];

                const currentQuestion = currentQuestionSet[currentQuestionKey].question;
                
                // Check if the question is already recorded, and overwrite if necessary
                const existingAudioIndex = audioData.findIndex(audio => audio.question === currentQuestion);

                let transcriptionText = await transcribeWithWhisper(openai, blob);
        
                if (existingAudioIndex !== -1) {
                    // Overwrite existing audio data
                    const updatedAudioData = [...audioData];
                    updatedAudioData[existingAudioIndex] = { question: currentQuestion, blob, audioURL };
                    setAudioData(updatedAudioData);
    
                    // Overwrite transcription                    
                    //const updatedTranscriptions = [...transcriptions];
                    //updatedTranscriptions[existingAudioIndex] = transcriptionText;
                    //setTranscriptions(updatedTranscriptions);
                } else {
                    // Add new recording
                    setAudioData(prev => [...prev, { question: currentQuestion, blob, audioURL }]);

                    // Add new transcription
                    //setTranscriptions(prev => [...prev, transcriptionText]);                     
                }

                //console.log('question set', questionSets[currentStep - 1])
                //console.log(' question key', currentQuestionKey)
    
                // Update interviewData with the audio and related metadata
                setInterviewData(prev => {
                    const updatedQuestion = {
                        ...prev.questions[questionSets[currentStep - 1]][currentQuestionKey],
                        //...prev.questions[currentQuestionSet][currentQuestionKey],
                        recording: true, // Mark the question as recorded
                        audioData: blob, // Store the Blob audio data
                        audio_file_size: blob.size, // File size in bytes
                        audio_time_start: startTime, // Start time of the recording
                        audio_time_end: endTime, // End time of the recording
                        audio_duration: duration, // Duration of the recording
                        audio_original_text: transcriptionText, // Original text before translation
                        anonymous_id: studyExpertData?.anonymous_id ?? '', // ID or name of the participant
                    };

                    const hasChanged = JSON.stringify(prev.questions[questionSets[currentStep - 1]][currentQuestionKey]) !== JSON.stringify(updatedQuestion);
                    if (!hasChanged) return prev;
    
                    return {
                        ...prev,
                        questions: {
                            ...prev.questions,
                            [questionSets[currentStep - 1]]: {
                                ...prev.questions[questionSets[currentStep - 1]],
                                [currentQuestionKey]: updatedQuestion,
                            },
                        },
                    };
                });
    
                setRecording(false);
                setLoading(false);
            }).catch(e => {
                console.error('Error processing recording:', e);
                setLoading(false);
            });
        }
    };

    // Handle the text input for the answer
    const handleTextInput = async () => {        

        if (!textInput) {
            setTextInput(true);
            setStartTime(getCurrentDateTime());
            setTextInputToggle(true);
            return;
        }

        if (!textAnswer) {
            console.error('Text input is empty!');
            setLoadingTextInput(false);
            return;
        } else {

            try {
                setLoadingTextInput(true);
                const endTime = getCurrentDateTime();
                const duration = calcDuration(startTime, endTime);

                const currentQuestionKey = questionKeys[currentQuestionIndex];
                setCurrentQuestionKey(currentQuestionKey);

                const currentQuestion = currentQuestionSet[currentQuestionKey].question;

                // Detect language and translate if necessary
                const isEnglish = await detectOriginalLanguage(openai, textAnswer); // returns 'EN' if English
                //console.log('Detected language:', isEnglish);
                const translatedText = isEnglish === 'EN' ? textAnswer : await translateToEnglish(openai, textAnswer);
                //console.log('Translated text:', translatedText);                                

                // Check if the question is already recorded or inputted, and overwrite if necessary
                const existingIndex = audioData.findIndex(audio => audio.question === currentQuestion);
                if (existingIndex !== -1) {
                    const updatedData = [...audioData];
                    updatedData[existingIndex] = { question: currentQuestion, textInput: translatedText };
                    setAudioData(updatedData);
                } else {
                    setAudioData(prev => [...prev, { question: currentQuestion, textInput: translatedText }]);
                }

                // Update interviewData with text input and related metadata
                setInterviewData(prev => {
                    const updatedQuestion = {
                        ...prev.questions[questionSets[currentStep - 1]][currentQuestionKey],
                        recording: true, // Mark the question as recorded, even if it's text input
                        audioData: null, // Clear the audio data
                        audio_file_size: null, // Clear the file size
                        audio_time_start: startTime,
                        audio_time_end: endTime,
                        audio_duration: duration,
                        audio_original_text: textAnswer, // Original text before translation
                        anonymous_id: studyExpertData?.anonymous_id ?? '', // ID or name of the participant
                        //translated_text: translatedText,
                    };

                    const hasChanged = JSON.stringify(prev.questions[questionSets[currentStep - 1]][currentQuestionKey]) !== JSON.stringify(updatedQuestion);
                    if (!hasChanged) return prev;

                    return {
                        ...prev,
                        questions: {
                            ...prev.questions,
                            [questionSets[currentStep - 1]]: {
                                ...prev.questions[questionSets[currentStep - 1]],
                                [currentQuestionKey]: updatedQuestion,
                            },
                        },
                    };
                });

                //setTextInput(false); => is set to false in transcription analysis hook
                setTextInputToggle(false);
                //setLoadingTextInput(false); => is set to false in transcription analysis hook
                setTextAnswer('');
            } catch (error) {
                console.error('Error processing text input:', error);
                setLoadingTextInput(false);
                setTextAnswer('');
            }         
        }
    };
    
    // Handle moving to next question
    const nextQuestion = () => {        
        if (currentQuestionIndex < questionKeys.length - 1) {
            // Move to next question
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (currentStep < totalQuestionSets) {
            // Move to next question set
            setCurrentStep(currentStep + 1);
            setCurrentQuestionIndex(0);
            //setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            console.log('Interview Finished!');
            // Handle submission or navigation to summary
        }
    };

    // Handle moving to previous question or set
    const prevQuestion = () => {
        //console.log('Before prevQuestion -> currentQuestionIndex:', currentQuestionIndex);
        //console.log('Before prevQuestion -> currentStep:', currentStep);
    
        if (currentQuestionIndex > 0) {
            // Move to the previous question in the same set
            setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
        } else if (currentStep > 1) {
            // Move to the previous step and set the last question index for that step
            setCurrentStep((prevStep) => {
                const previousStep = prevStep - 1;
                const previousQuestionKeys = Object.keys(interviewData.questions[Object.keys(interviewData.questions)[previousStep - 1]]);
                setCurrentQuestionIndex(previousQuestionKeys.length - 1);
                return previousStep;
            });
        }
    
        /*
        setTimeout(() => {
            console.log('After prevQuestion -> currentQuestionIndex:', currentQuestionIndex);
            console.log('After prevQuestion -> currentStep:', currentStep);
        }, 0);
        */
    };    

    // Handle the final submission and processing of the interview answers
    const handleSubmit = async () => {

        //console.log('Audio Data:', audioData);
        //console.log('study expert data', studyExpertData);

        if (!studyExpertData || studyExpertData === undefined) {
            console.error('Study Expert Data is missing!');
            return;
        }
        
        try {
            // set loading on the submit button
            setLoadingSubmit(true);
            // Update the end timestamp and duration when the interview is completed            
            const endTimestamp = getCurrentDateTime();            

            // Upload each audio Blob to Firebase Storage and update `interviewData` with the URLs
            const updatedQuestions = { ...interviewData.questions };
                    
            for (const [sectionKey, section] of Object.entries(updatedQuestions)) {
                for (const [qKey, question] of Object.entries(section)) {
                    if (question.audioData instanceof Blob) {
                        const filename = `${studyExpertData?.anonymous_id}_${sectionKey}_${qKey}.mp3`; // Create a unique filename
                        question.audioData = await uploadAudioToStorage(question.audioData, filename, studyExpertData?.anonymous_id); // Upload and get URL
                    } else if (question.textInput) {
                        // Clear the audio data and file size if it's text input
                        question.audioData = null;
                        question.audio_file_size = null;
                    }
                }
            }

            const updatedInterviewData = {
                ...interviewData,
                study_step_end: {
                    ...interviewData.study_step_end,
                    value: endTimestamp,
                    modified: true,
                    anonymous_id: studyExpertData?.anonymous_id ?? '',
                },
                study_step_duration: {
                    ...interviewData.study_step_duration,
                    value: getTimeDifferenceInHHMMSS(interviewData.study_step_start.value, endTimestamp),
                    modified: true,
                    anonymous_id: studyExpertData?.anonymous_id ?? '',
                },
                questions: updatedQuestions
            };

            setInterviewData(updatedInterviewData);

            /*
            setInterviewData((prev) => ({
                ...prev,
                study_step_end: {
                    ...prev.study_step_end,
                    value: endTimestamp,
                    modified: true,
                    anonymous_id: studyExpertData?.anonymous_id ?? '',
                },
                study_step_duration: {
                    ...prev.study_step_duration,
                    value: getTimeDifferenceInHHMMSS(prev.study_step_start.value, endTimestamp),
                    modified: true,
                    anonymous_id: studyExpertData?.anonymous_id ?? '',
                },
                questions: {
                    ...prev.questions,
                }
            }));
            */
            //console.log('Prepared interview data:', updatedInterviewData);
            //console.log('Interview data:', interviewData);            
            
            saveInterviewData(updatedInterviewData);
            console.log('Interview data saved successfully!');

        } catch (error) {
            console.error('Error saving interview data:', error);
        }

        // triggers the next study step (interview)
        setShowModalStudy(true);
        // set loading on the submit button
        setLoadingSubmit(false);
    };

    // Progress bar
    const renderProgressBar = () => (
        <ProgressBar
        now={calculateProgress()}
        label={`${Math.floor(calculateProgress())}%`}
        style={{
            height: '15px',
            borderRadius: '5px',
            marginBottom: '20px',
            color: 'gray',
            textAlign: 'right',
        }}
        variant="success"
        />
    );

    // Check if the current question is already recorded
    const isQuestionAlreadyRecorded = () => {
        const currentQuestion = currentQuestionSet[questionKeys[currentQuestionIndex]].question;
        return audioData.some(audio => audio.question === currentQuestion);
    };
    
    // Update the button label and variant for the audio recording
    const getButtonLabel = () => {
        if (isQuestionAlreadyRecorded()) {
            return recording ? 'Stop Recording' : 'Overwrite Answer';
        }
        return recording ? 'Stop Recording' : (
            <>
                <i className="bi bi-mic" style={{ marginRight: '5px' }}></i> Start Recording
            </>
        );
    };

    // Update the button label and variant for the text input
    const getButtonLabelTextInput = () => {
        if (isQuestionAlreadyRecorded()) {
            return textInput ? 'Submit Text' : <i className="bi bi-arrow-clockwise"></i>;
        }
        return textInput ? 'Submit Text' : (
        <>
            or type <i className="bi bi-keyboard"></i>
        </>
        );
    };
    
    // Update the button variant based on the recording
    const getButtonVariant = () => {
        if (isQuestionAlreadyRecorded()) {
            return (
                recording ? 'danger' : 'warning'
            ); // Warning if already recorded
        }
        return recording ? 'danger' : 'success'; // Success if no previous recording
    };    

    // Update the button variant based on the text input
    const getButtonVariantTextInput = () => {
        if (isQuestionAlreadyRecorded()) {
            return (
                textInput ? 'danger' : 'outline-warning'
            ); // Warning if already recorded
        }
        return textInput ? 'danger' : 'outline-success'; // Success if no previous recording
    };    
    
    return (
        <Container fluid>

            {/* Interview */}
            <Row align="center">
                <h1 style={{ marginTop: '50px', marginBottom: '30px' }}>
                <b>Interview</b>
                </h1>
            </Row>

            {/* Page indicator 
            <Row align="center" style={{ marginTop: '30px' }}>
                <PageIndicator
                    currentStep={currentStep}
                    totalQuestionSets={totalQuestionSets}
                    currentQuestionIndex={currentQuestionIndex}
                    questionKeys={questionKeys}
                    prevQuestion={prevQuestion}
                    nextQuestion={nextQuestion}
                    recording={recording}
                    handleSubmit={handleSubmit}
                    loadingSubmit={loadingSubmit}
                />
            </Row>
            */}

            {/* Progress Bar */}
            <Row align="center" style={{ maxWidth: '500px', margin: '0 auto' }}>
                {renderProgressBar()}
            </Row>

            {/* Display Question Set and Current Question Number */}
            <Row align="center">
                <DisplayQuestionStage
                    currentStep={currentStep}
                    totalQuestionSets={totalQuestionSets}
                    currentQuestionIndex={currentQuestionIndex}
                    questionKeys={questionKeys}
                />
            </Row>

            {/* Outer Light Blue Background */}
            <div style={{ backgroundColor: '#e3f2fd', padding: '30px 0', borderRadius: '12px', marginTop:'50px' }}> {/* Light blue with vertical padding */}
                
                {/* Inner White Container with Rounded Corners */}
                <div style={{
                    backgroundColor: '#ffffff',
                    padding: '20px',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    maxWidth: '800px',
                    margin: '0 auto'
                }}>
                    {/* Display Current Question */}
                    <Row className="justify-content-center" align="center">
                        <h1 style={{ fontSize: '2rem', textAlign: 'center', marginTop: '20px', marginBottom: '10px' }}>
                            {currentQuestionSet[questionKeys[currentQuestionIndex]].question}
                        </h1>
                        <small style={{ textAlign: 'center', color: '#555' }}>Deutsch: {currentQuestionSet[questionKeys[currentQuestionIndex]].question_de}</small>
                    </Row>     

                    {/* Audio Recording and Text Input Options */}
                    <Row className="justify-content-center">
                        <Col md={12} style={{ textAlign: 'center' }}>
                            <div style={{ marginTop: '30px' }}>
                                {/* Audio Recording Button */}
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Record your answer to the question.</Tooltip>}>
                                    <Button
                                        variant={getButtonVariant()}
                                        onClick={handleRecord}
                                        disabled={loading || textInputToggle || loadingTextInput}
                                        //style={{ marginBottom: '15px' }}
                                    >
                                        {loading ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...
                                            </>
                                        ) : (
                                            <>
                                                {getButtonLabel()}
                                                <RecordingComponent recording={recording} />
                                            </>
                                        )}
                                    </Button>
                                </OverlayTrigger>

                                {/* Text Input Button */}
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Enter answer as text input instead of audio.</Tooltip>}>
                                    <Button 
                                        variant={getButtonVariantTextInput()}
                                        style={{ marginLeft: '10px' }}
                                        disabled={recording}
                                        onClick={handleTextInput}
                                    >
                                        {loadingTextInput ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...
                                            </>
                                        ) : (
                                            <>
                                                {getButtonLabelTextInput()}
                                                <RecordingComponent recording={textInput} />                             
                                            </>
                                        )}                
                                    </Button>
                                </OverlayTrigger>

                                {/* Display "Stored" Message */}
                                {isQuestionAlreadyRecorded() && !recording && !textInput && (
                                    <>
                                        <Button variant="success" style={{ marginLeft: '10px' }} disabled>
                                            <i className="bi bi-check"></i> Stored
                                        </Button>
                                        <div style={{ marginTop: '10px', color: 'orange' }}>
                                            <b>Warning:</b> This question has already been recorded. Overwrite the answer if needed.
                                        </div>
                                    </>
                                )}

                                {/* Display Text Input Box */}
                                {textInput && !loadingTextInput && (
                                    <div style={{ marginTop: '20px' }}>
                                        <TextInputBox textInput={textInput} textAnswer={textAnswer} setTextAnswer={setTextAnswer} />
                                    </div>
                                )}
                            </div>           

                            {/* Display Button to see transcriptions right aligned */}
                            {audioData.length > 0 && (
                            <div style={{ textAlign: 'right', marginTop:'100px' }}> {/* Adjust padding as needed */}
                                <Button 
                                    variant="link" 
                                    size="sm" 
                                    onClick={() => setShowRecordedAudioTable(!showRecordedAudioTable)}
                                    style={{ color: '#6c757d', textDecoration: 'none' }} // Customize color and remove underline
                                >
                                    {showRecordedAudioTable ? 
                                        <>
                                            <i className="bi bi-x-circle"></i> Hide Transcriptions
                                        </>
                                        : (
                                        <>
                                            <i className="bi bi-card-text"></i> View Transcriptions
                                        </>
                                    )}
                                </Button>
                            </div>                    
                            )}                                                                
                        </Col>
                    </Row>
                </div>
            </div>

            {/* Page indicator */}
            <Row align="center" style={{ marginTop: '30px' }}>
                <PageIndicator
                    currentStep={currentStep}
                    totalQuestionSets={totalQuestionSets}
                    currentQuestionIndex={currentQuestionIndex}
                    questionKeys={questionKeys}
                    prevQuestion={prevQuestion}
                    nextQuestion={nextQuestion}
                    recording={recording}
                    textInput={textInput}
                    handleSubmit={handleSubmit}
                    loadingSubmit={loadingSubmit}
                    isProcessed={isProcessed}
                    audioData={audioData}
                    totalNumberOfQuestions={totalQuestions}
                />
            </Row>

            {/* Display Recorded Audios; only display when questions answered */}
            {showRecordedAudioTable && audioData.length > 0 && (
            <Row className="justify-content-center" align="center" style={{ maxWidth: '1600px', margin: '0 auto', marginTop: '50px' }}>
                <hr style={{ maxWidth:'800px' }} />
                <RecordedAudioTable 
                    interviewData={interviewData}      
                />
            </Row>
            )}

        </Container>
    );
}


export { default as Interview } from './Interview';