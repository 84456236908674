/**
 * State to hold the survey data
 *
 * Description: Provides a state to hold the survey data.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 */

import { useState } from 'react';

/**
   * State to hold the survey data.
   * The data object will be updated as the user progresses through the survey form.
   * Data will be sent to the backend when the user completes the survey form.
   */
export const useSurveyDataHook = () => {
    const [surveyData, setSurveyData] = useState({       
        // no personal data is collected and stored 
        anonymous_id: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        mode: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        role: {
            value: 'user',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        consent: {
            value: false,
            source: 'study',
            modified: false,    
            anonymous_id: '',
        },
        consent_date: {
            value: '',
            source: 'study',
            modified: false,    
            anonymous_id: '',
        },
        place: {
            value: '',
            source: 'study',
            modified: false,            
            anonymous_id: '',
        },
        experience: {
            value: '',
            source: 'study',
            modified: false,            
            anonymous_id: '',
        },
        expert_field: {
            value: '',
            source: 'study',
            modified: false,            
            anonymous_id: '',
        },
        yearOfBirth: {
            value: '',
            source: 'study',
            modified: false,            
            anonymous_id: '',
        },
        study_step: {
            value: 0,
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        study_step_start: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        study_step_end: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        study_step_duration: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        // (1) question set intro
        feeling: {
            expert_feeling: {
                setLabel: 'feeling',
                question: 'How did you feel about the UCM tool and modeling use cases?',
                answer_set: ['angry', 'sad', 'neutral', 'happy', 'very happy'],
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            expert_work_level: {
                setLabel: 'feeling',
                question: 'Which category best describes your expertise level with use case modeling?',
                answer_set: ['Beginner', 'Intermediate', 'Advanced'],
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            expert_work_layer: {
                setLabel: 'feeling',
                question: 'What category best describes your daily doing?',
                answer_set: ['Conceptional', 'Strategic', 'Execution', 'Operational'],
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            expert_confidence: {
                setLabel: 'feeling',
                question: 'How confident did you feel executing the study with respect to modeling use cases?',
                answer_set: ['Not confident', 'Not really confident', 'Somewhat confident', 'Confident', 'Very confident'],
                answer_num_set: [0, 1, 2, 3, 4],
                answer: null, // default value
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
        },
        // (2) question set added value (modeling approach)
        addedValue: {
            ucm_usefullness: {
                setLabel: 'addedValue',
                question: 'How would you rate the usefulness of the implemented UCM four-step approach for use case modeling?',
                answer_set: ['Not useful', 'Neglectable', 'Somewhat useful', 'Useful', 'Very useful'],
                answer_num_set: [0, 1, 2, 3, 4],
                answer: null, // default value
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            ucm_effectiveness: {
                setLabel: 'addedValue',
                question: 'How strongly do you agree with the following statement: "The UCM Tool is effective for use case modeling."', // tool instead of method
                answer_set: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            ucm_most_useful_aspects: {
                setLabel: 'addedValue',
                question: 'Which aspects of the UCM method did you find most useful? (Select up to three)',
                answer_set: [
                    'Four-step approach', 
                    'Info View', 
                    'Scenario Model', 
                    'Actor Map', 
                    'Component Diagram', 
                    'LLM Layer (UCM Co-Pilot)', 
                    'Domain-Specific Semantic Models', 
                    'UI Design'
                ],
                answer: [],
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            ucm_method_clarity: {
                setLabel: 'addedValue',
                question: 'How clear was the UCM method presented to you overall?',
                answer_set: ['Not clear', 'Not so clear', 'Somewhat clear', 'Clear', 'Very clear'],
                answer_num_set: [0, 1, 2, 3, 4],
                answer: null, // default value
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            /*
            ucm_ranked_aspects: {
                setLabel: 'addedValue',
                question: 'Rank the following features of the UCM method in terms of importance (1 being most important):',
                answer_set: [
                    'Four-step approach', 
                    'Info View', 
                    'Scenario Model', 
                    'Actor Map', 
                    'Component Diagram', 
                    'LLM Layer (UCM Co-Pilot)', 
                    'Domain-Specific Semantic Models'
                ],
                answer: {},
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            */
            ucm_additional_comments: {
                setLabel: 'modelUsage',
                question: 'Do you have any additional comments on the UCM method?',
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',            
            },
        },
        // (3) question set semantic model usage
        modelUsage: {
            model_input: {
                setLabel: 'modelUsage',
                question: 'How would you overall rate the generated input given by UCM Co-Pilot (LLM + Semantic Model layer)?',
                answer_set: ['Not useful', 'Neglectable', 'Somewhat useful', 'Useful', 'Very useful'],
                answer_num_set: [1, 2, 3, 4, 5],
                answer: 0,
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            model_llm_integration: {
                setLabel: 'modelUsage',
                question: 'How useful did you find the integration of LLM (Large Language Models) in the UCM process?',
                answer_set: ['Not useful', 'Neglectable', 'Somewhat useful', 'Useful', 'Very useful'],
                answer_num_set: [1, 2, 3, 4,5],
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            model_semantic_clarity: {
                setLabel: 'modelUsage',
                question: 'How clear were the semantic models knowledge input and their role in the UCM method?',
                answer_set: ['Not clear', 'Neglectable', 'Somewhat clear', 'Clear', 'Very clear'],
                answer_num_set: [1, 2, 3, 4, 5],
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            model_agreed_aspects: {
                setLabel: 'modelUsage',
                question: 'What aspects may you agree to with regards to the given input?',
                answer_set: [
                    'Relevance to the domain',
                    'Clarity of the inputs',
                    'Simplicity of the suggested elememts',
                    'Completeness of the information',
                ],
                answer: [],
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            model_additional_comments: {
                setLabel: 'modelUsage',
                question: 'Do you have any additional comments on the model usage?',
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',            
            },
        },
        /*
        // (4) question set UI design
        uiDesign: {
            ui_clarity: {
                setLabel: 'uiDesign',
                question: 'How would you rate the overall clarity of the UI?',
                answer_set: [1, 2, 3, 4, 5],
                answer: 0,
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                                
            },
            ui_simplicity: {
                setLabel: 'uiDesign',
                question: 'How would you rate the user navigation throughout the study?',
                answer_set: ['Very simple', 'Simple', 'Somewhat simple', 'Complicated', 'Very complicated'],
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                                
            },
            ui_overall_aspects: {
                setLabel: 'uiDesign',
                question: 'Rate the following aspects of the UCM method:',
                answer_set: [
                    'Navigation',
                    'Information layout',
                    'Content clarity',
                    'Interactivity',
                    'Visual design',
                    'Consistency',
                    'Ease of use',
                    'Effectiveness',
                    'Usefulness of semantic models',                    
                ],
                answer_ids: ['navigation', 'layout', 'clarity', 'interactivity', 'design', 'consistency', 'ease', 'effectiveness', 'usefulness'],
                answer: {},
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                                
            },
        },
        */
        // (4) question set experience
        userExperience: {
            reproducibility: {
                setLabel: 'userExperience',
                question: 'Do you think the UCM method is relevant for practical use cases in the industry as a starting point in the thought-process?',
                answer_set: ['Highly reproducible', 'Reproducible', 'Somewhat reproducible', 'Not really reproducible', 'Not reproducible'],
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            overall_experience: {
                setLabel: 'userExperience',
                //question: 'Overall, how would you rate your experience with this use case modeling method?',
                question: 'How well do you think the UCM method represented your understanding of a use case, as you would identify it on your own?',
                answer_set: ['Excellent', 'Very good', 'Average', 'Fair', 'Poor'],
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',                
            },
            additional_comments: {
                setLabel: 'userExperience',
                question: 'Please leave any additional comments or feedback.',
                answer: '',
                source: 'study',
                anonymous_id: '',
                created_timestamp: '',            
            },
        }      
    });
    return [surveyData, setSurveyData];
};