/**
 * Modal Add Edge
 *
 * Description: Modal to add an edge between two nodes
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-15
 * 
 */

import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddEdgeModal = ({ show, onHide, nodes, onAddEdge, fromNode }) => {
    const [toNodeId, setToNodeId] = useState('');

    const handleAddEdge = () => {
        if (!toNodeId) {
            alert('Please select a target node.');
            return;
        }

        // Construct the new edge from source to target
        const newEdge = {
            from: fromNode.id,
            to: toNodeId,
        };

        // Trigger the parent handler to add the edge
        onAddEdge(newEdge);

        // Reset and close the modal
        setToNodeId('');
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Edge</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formFromNode">
                        <Form.Label>Source Actor</Form.Label>
                        <Form.Control
                            as="select"
                            value={fromNode?.id || ''}
                            disabled
                        >
                            <option value={fromNode?.id}>{fromNode?.label}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formToNode">
                        <Form.Label>Target Actor</Form.Label>
                        <Form.Control
                            as="select"
                            value={toNodeId}
                            onChange={(e) => setToNodeId(e.target.value)}
                        >
                            <option value="">Select target node...</option>
                            {nodes.map((node) => (
                                <option key={node.id} value={node.id}>
                                    {node.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleAddEdge}>
                    Add Edge
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default AddEdgeModal;