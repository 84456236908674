/**
 * Describes the procedure for the user study
 *
 * Description: Provides the elements
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-15
 * Notes: /
 */

import React, { useState } from 'react';

import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import { doc, getDoc } from 'firebase/firestore';
import { db } from '../components/auth_mgmt/auth_config';

import { ElemToast } from '../components/elems/elem_toast';

import { Classification } from '../components/study_handling/study_classification.jsx';
import { ConsentFormModal } from '../components/study_handling/study_consent';

export default function Participate() {

    const navigate = useNavigate();

    const [showConsentForm, setShowConsentForm] = useState(false);
    const [accessCode, setAccessCode] = useState('');
    const [consentGiven, setConsentGiven] = useState(false);
    const [expertData, setExpertData] = useState(null);

    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => setShowToast(!showToast);
    const label = 'Please agree to the consent form to proceed. This way we are able to integrate the use case in our research study. Thank you.';

    //const [showClassification, setShowClassification] = useState(true);

    const handleSubmit = () => {
        //console.log('Expert Data:', expertData);
    
        navigate('/Instructions', { state: { expertData } });
    };

    const handleSubmitAccessCode = (e) => {
        e.preventDefault();
        checkAccessCode(accessCode);
    };

    const [error, setError] = useState('');

    const checkAccessCode = async (code) => {

        setError('');
      
        try {
          const docRef = doc(db, 'study_key', code);
          const docSnap = await getDoc(docRef);
      
          if (docSnap.exists()) {
            console.log('Document Data:', docSnap.data());
            //onKeyVerified(); // Key verified

            setShowConsentForm(true);

          } else {
            alert('Invalid access code. Please try again.');
            setError('Invalid key.');
          }
        } catch (err) {
          setError('Error verifying the key.');
          console.error('Error:', err.message);
        }
    };

    const handleConsentSubmit = (formData) => {
        //console.log('Consent Form Data Submitted:', formData);
        setExpertData(formData);
        setConsentGiven(true);
        setShowConsentForm(false);
    };

    /*
    const handleClassification = () => {
        setShowClassification(!showClassification);
    }
    */

    return (
        <Container fluid>
            {/* Intro */}
            <Row align='center'>      
                <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Participate</b></h1>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                    Experts are invited to participate in this mixed-methods research study 
                    of applying the UCM tool, followed by a semi-structured interview and brief survey.                   
                </p>
            </Row>    
                                 
                
            {/* Classification 
            {showClassification && */}
            <Row>
                <h3 align='center' style={{marginTop: '30px', marginBottom: '30px'}}>
                    Research Scope and Classification
                </h3>
                <div style={ { maxWidth: '800px', margin: '0 auto' } }>
                    <Classification />
                </div>

                <div align='center' style={ { marginTop:'30px' } }>
                    <small>The entire study takes approximately 70 minutes, including a brief introduction.</small>
                </div>

                {/* Start study by entering the participant code 
                <div className="d-flex justify-content-center">
                    <Button variant="success" onClick={handleClassification} style={ { marginTop:'30px', minWidth:'200px', fontSize:'20px' } }> {/* minHeight:'80px', *
                        <i className="bi bi-arrow-right" style={{ marginRight: '8px', fontSize:'20px' }}></i>
                        Enter participant consent
                    </Button>
                </div>
                */}
                <div className="d-flex justify-content-center" style={ { marginTop:'30px' } }>
                    {/* Access Code Form */}
                    <Form onSubmit={handleSubmitAccessCode} className="d-flex align-items-center">
                        <Form.Group className="mb-0 me-2" controlId="accessCode">
                            <Form.Control
                                type="text"
                                placeholder="Enter study access code"
                                required
                                value={accessCode}
                                onChange={(e) => setAccessCode(e.target.value)}
                                style={{ height: '38px' }}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="btn" style={{ height: '38px' }}>
                            Enter
                        </Button>
                    </Form>

                    {/* Success Alert */}
                    {consentGiven && (
                        <Alert variant="success" className="ms-2 mb-0 d-flex align-items-center" style={{ height: '38px' }}>
                            <i className="bi bi-check-circle" style={{ marginRight: '8px' }}></i>
                            Participant Consent Confirmed
                        </Alert>
                    )}

                    {/* Error Alert */}
                    {error && (
                        <Alert variant="danger" className="ms-2 mb-0 d-flex align-items-center" style={{ height: '38px' }}>
                            <i className="bi bi-x-circle" style={{ marginRight: '8px' }}></i>
                            {error}
                        </Alert>
                    )}
                </div>  
            </Row>            

            {/* Consent Form 
            {!showClassification &&
            <Row>
                <h3 align='center' style={{marginTop: '30px', marginBottom: '30px' }}>Accessibility and Consent</h3>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                    The data acquired is made available in the UCM repository for you and everyone interested in this research. All your personal data concerned with the study are anonymized.
                </p>                                            
            </Row>
            }
            */}   
            
            {/* Start Study Section */}
            {consentGiven && (
                <Row className="justify-content-center mb-4" style={ { marginTop:'60px' } }>
                    <Col md={6} className="text-center">
                        <Button variant="success" onClick={handleSubmit} > {/* style={ { minHeight:'80px', minWidth:'200px', fontSize:'20px' } } */}
                            <i className="bi bi-rocket" style={{ marginRight: '8px', fontSize:'20px' }}></i>
                            Anonymize your data
                        </Button>
                    </Col>
                </Row>
            )}

            <ConsentFormModal
                show={showConsentForm}
                handleClose={() => setShowConsentForm(false)}
                handleConsentSubmit={handleConsentSubmit}
            />
            
            {/* show toast */}
            {ElemToast(showToast, toggleToast, label)}
        
        </Container>
    );
};

export { default as Participate } from './Participate';