import React, { useState } from 'react';
import { Row, Col, Button, Form, OverlayTrigger, Tooltip, Accordion, Modal } from 'react-bootstrap';

import { getCurrentDate, getCurrentDateTime } from '../utils/utils_date';
import { UserInformation } from '../auth_mgmt/auth_handling';

import Papierboot from '../../assets/img/Papierboot.png';

// Help modal about scenarios
const HelpModal = ({ show, handleClose }) => {

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>How to model scenarios?</Modal.Title>
      </Modal.Header>
        <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <p>
          A scenario is a description of a use case from the perspective of a user or actor. It describes a specific sequence of actions or events that occur when a user interacts with a system or application. Scenarios are used to illustrate how a use case works in practice and provide a detailed account of the steps involved in achieving a particular goal or task.
        </p>
        <p>
          Scenarios can be used to identify potential problems or issues with a use case, as well as to help stakeholders understand the requirements and functionality of a system.
        </p>
        <h6>
          Example scenarios for a smart automated parking system:
        </h6>
        <ul>
          <li>
            <strong>Finding Parking Lot:</strong> User searches for an available parking lot.
          </li>
          <li>
            <strong>Automated Drive:</strong> Vehicle autonomously drives to the parking lot.
          </li>
          <li>
            <strong>Automated Parking:</strong> Vehicle parks itself in the assigned spot.
          </li>
          <li>
            <strong>Transaction and Payment:</strong> Payment is processed automatically.
          </li>
          <li>
            <strong>Call Vehicle for Pick-Up:</strong> User requests the vehicle for pick-up.
          </li>
        </ul>
        <p>
          Once added you can add a pre-condition, post-condition, constraint, or assumption to each scenario to further define the context and requirements.
        </p>
        <ul>
          <li>
            <strong>Pre-conditions:</strong> Conditions that must be true before the scenario can be executed.
          </li>
          <li>
            <strong>Post-conditions:</strong> Conditions that must be true after the scenario has been executed.
          </li>
          <li>
            <strong>Constraints:</strong> Limitations or restrictions that apply to the scenario.
          </li>
          <li>
            <strong>Assumptions:</strong> Assumptions that are made about the scenario.
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Modal to add a new scenario
const AddScenarioModal = ({ show, handleClose, scenarioName, setScenarioName, scenarioDescription, setScenarioDescription, handleAddScenario }) => {

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add a New Scenario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Scenario Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter scenario name"
              value={scenarioName}
              onChange={(e) => setScenarioName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder='Enter scenario description...'
              value={scenarioDescription}
              onChange={(e) => setScenarioDescription(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='primary' onClick={() => handleAddScenario(scenarioName, scenarioDescription)}>
          Add Scenario
        </Button>
      </Modal.Footer>
    </Modal>
  );
};          

// Modal to quickly add new scenario labels in a table like format
const AddQuickScenarioModal = ({ show, data, handleClose, handleAddMultipleScenarios }) => {

  const [scenarios, setScenarios] = useState([
    { name: '', 
      description: '',
      source: 'manually',
      modified: false,
      created_timestamp: getCurrentDateTime(),
      updated_timestamp: getCurrentDateTime(),
      updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName,
      uid: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().uid,
      version: 1,
      priority: 'medium',
      relevance: 'medium' 
    }]);

  const handleAddRow = () => {
    setScenarios([...scenarios, 
      { name: '', 
        description: '',
        source: 'manually',
        modified: false,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName,
        uid: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().uid,
        version: 1,
        priority: 'medium',
        relevance: 'medium'
      }]);
  };

  const handleRemoveRow = (index) => {
    const updatedScenarios = scenarios.filter((_, i) => i !== index);
    setScenarios(updatedScenarios);
  };

  const handleInputChange = (index, field, value) => {
    const updatedScenarios = scenarios.map((scenario, i) =>
      i === index ? { ...scenario, [field]: value } : scenario
    );
    setScenarios(updatedScenarios);
  };

  const handleConfirmAdd = () => {
    // Filter out empty rows
    const validScenarios = scenarios.filter(
      (scenario) => scenario.name.trim() !== ''
    );

    if (validScenarios.length === 0) {
      alert('Please add at least one valid scenario.');
      return;
    }

    handleAddMultipleScenarios(validScenarios);
    setScenarios([{ name: '', description: '' }]); // Reset modal state
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add multiple Scenarios at once</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th style={{ width: '40%' }}>Scenario Name</th>
                <th style={{ width: '50%' }}>Description</th>
                <th style={{ width: '10%' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {scenarios.map((scenario, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={scenario.name}
                      onChange={(e) =>
                        handleInputChange(index, 'name', e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      placeholder="Brief Description"
                      value={scenario.description}
                      onChange={(e) =>
                        handleInputChange(index, 'description', e.target.value)
                      }
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveRow(index)}
                      disabled={scenarios.length === 1}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleClose}>
          <i className="bi bi-x-circle"></i> Cancel
        </Button>
        <Button variant="outline-primary" onClick={handleConfirmAdd}>
          <i className="bi bi-floppy"></i> {scenarios.length} Scenarios
        </Button>
        <Button variant="outline-primary" onClick={handleAddRow}>
            <i className="bi bi-plus-circle"></i> Add more
          </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Action = ({ data, setData, useCaseDetails }) => {

  // State to manage scenario inputs
  const [scenarios, setScenarios] = useState(data.actions.value || []);
  // State to manage condition inputs
  const [conditions, setConditions] = useState(data.conditions.value || []);

  // State to manage help / add modal
  const [showAdd, showAddModal] = useState(false);
  const [showQuickAdd, showQuickAddModal] = useState(false);
  const [showHelp, showHelpModal] = useState(false);

  // State to manage scenario name and description
  const [scenarioName, setScenarioName] = useState('');
  const [scenarioDescription, setScenarioDescription] = useState('');

  // view state expanded or collapsed
  const [expandedKeys, setExpandedKeys] = useState([]);

  // Update the data object with the use case details
  /*
  useEffect(() => {
    
    if (!useCaseDetails || useCaseDetails.length === 0) return;

    // new scenarios based on UCM input
    const newScenarios = [
      {
        name: extractScenario(useCaseDetails[7]?.response || '')[0],
        description: extractScenario(useCaseDetails[7]?.response || '')[1]
      },
      {
        name: extractScenario(useCaseDetails[8]?.response || '')[0],
        description: extractScenario(useCaseDetails[8]?.response || '')[1]
      },
      {
        name: extractScenario(useCaseDetails[9]?.response || '')[0],
        description: extractScenario(useCaseDetails[9]?.response || '')[1]
      }
    ];

    // new conditions based on UCM input
    const newConditions = [
      {
        scenario: "Basic Scenario",
        preConditions: extractCondition(useCaseDetails[10]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[11]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[12]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[13]?.response || '')[1]
      },
      {
        scenario: "Advanced Scenario",
        preConditions: extractCondition(useCaseDetails[14]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[15]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[16]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[17]?.response || '')[1]
      },
      {
        scenario: "Cutting-edge Scenario",
        preConditions: extractCondition(useCaseDetails[18]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[19]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[20]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[21]?.response || '')[1]
      }
    ];

    setScenarios(newScenarios);
    setConditions(newConditions);

    setData(prevData => ({
      ...prevData,
      actions: newScenarios,
      conditions: newConditions,
    }));
  
  }, [useCaseDetails, setData, isPrompt, setIsPrompt]);
  */

  // Handle open modal to add a new scenario
  const handleAdd = () => {
    showAddModal(true);
  };

  // Function to handle adding a new scenario
  /*
  const handleAddScenario0 = () => {
    let scenarioName = '';
    let nameExists = true;

    const isNameExists = (name) => {
      return scenarios.some(scenario => scenario.name === name);
    };

    // Keep prompting until a unique name is provided or the user cancels
    while (nameExists) {
      scenarioName = window.prompt('Enter the name of the new scenario:', 'Manually created');
      if (!scenarioName) return;

      // Check if the name already exists
      nameExists = isNameExists(scenarioName);

      if (nameExists) {
        alert('A scenario with this name already exists. Please enter a different name.');
      }
    }

    const newScenarios = [
      ...scenarios,
      { name: scenarioName, description: '' }
    ];
    setScenarios(newScenarios);
  
    const newConditions = [
      ...conditions,
      { preConditions: '', postConditions: '', constraints: '', assumptions: '' }
    ];
    setConditions(newConditions);

    // Close the modal
    showAddModal(false);

    // Update data object with new scenarios in the tracking object
    setData(prevData => ({
      ...prevData,
      actions: {
        ...prevData.description,
        value: newScenarios, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.description.version + 1 // Increment the version
      },
      conditions: {
        ...prevData.conditions,
        value: newConditions, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1 // Increment the version
      }
    }));
  };
  */

  const handleAddScenario = (scenarioName = '', scenarioDescription = '') => {
    
    if (!scenarioName.trim()) {
      alert('Scenario name cannot be empty.');
      return;
    }
  
    // Check for duplicates
    const isDuplicate = scenarios.some(scenario => scenario.name === scenarioName);
    if (isDuplicate) {
      alert('A scenario with this name already exists. Please use a different name.');
      return;
    }
  
    // Add the new scenario
    const newScenarios = [
      ...scenarios,
      { 
        name: scenarioName, 
        description: scenarioDescription,
        source: 'manually',
        modified: false,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName,
        uid: UserInformation().uid,
        version: 1,
        priority: 'medium',
        relevance: 'medium'
      }
    ];
    setScenarios(newScenarios);
  
    // Add the default conditions for the new scenario
    const newConditions = [
      ...conditions,
      { 
        preConditions: '', 
        postConditions: '', 
        constraints: '', 
        assumptions: '',
        source: 'manually',
        modified: false,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName,
        uid: UserInformation().uid,
        version: 1,
        priority: 'medium',
        relevance: 'medium'
      }
    ];
    setConditions(newConditions);
  
    // Update data object with new scenarios and conditions
    setData(prevData => ({
      ...prevData,
      actions: {
        ...prevData.actions,
        value: newScenarios, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous // Record who updated it
        uid: UserInformation().uid, // Record the user ID
        version: prevData.actions.version + 1 // Increment the version
      },
      conditions: {
        ...prevData.conditions,
        value: newConditions, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous // Record who updated it
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1 // Increment the version
      }
    }));
  
    // Reset the input fields
    setScenarioName('');
    setScenarioDescription('');

    // Close the modal
    showAddModal(false);
  };

  // Function to quickly add new scenarios
  const handleQuickAdd = () => {
    showQuickAddModal(true);
  };

  const handleAddMultipleScenarios = (newScenariosList) => {
    // Ensure unique names and no duplicates in the current scenarios
    const uniqueNewScenarios = newScenariosList.filter(
      (newScenario) => !scenarios.some((existingScenario) => existingScenario.name === newScenario.name)
    );
  
    // Append new scenarios to the current list
    const updatedScenarios = [...scenarios, ...uniqueNewScenarios];
    setScenarios(updatedScenarios);
  
    // Add corresponding conditions (empty by default) for each new scenario
    const updatedConditions = [
      ...conditions,
      ...uniqueNewScenarios.map(() => ({
        preConditions: '',
        postConditions: '',
        constraints: '',
        assumptions: '',
        source: 'manual',
        modified: false,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName,
        uid: UserInformation().uid,
        version: 1,
        priority: 'medium',
        relevance: 'medium'
      })),
    ];
    setConditions(updatedConditions);
  
    // Update the data object with new scenarios and conditions
    setData((prevData) => ({
      ...prevData,
      actions: {
        ...prevData.actions,
        value: updatedScenarios, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous // Record who updated it
        uid: UserInformation().uid, // Record the user ID
        version: prevData.actions.version + 1, // Increment the version
      },
      conditions: {
        ...prevData.conditions,
        value: updatedConditions, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous // Record who updated it
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1, // Increment the version
      },
    }));
  };  

  // Function to validate scenario name
  const handleScenarioNameChange = (index, newName) => {
    const updatedScenarios = [...scenarios];
    updatedScenarios[index] = { ...updatedScenarios[index], name: newName };
    setScenarios(updatedScenarios);
  
    // Update data object with the new name
    setData((prevData) => ({
      ...prevData,
      actions: {
        ...prevData.actions,
        value: updatedScenarios,
        modified: true,
        updated_timestamp: getCurrentDate(),
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous
        uid: UserInformation().uid,
        version: prevData.actions.version + 1,
      },
    }));
  };

  // Function to validate scenario name
  const validateScenarioName = (index) => {
    const currentName = scenarios[index].name;
  
    // Check for duplicates
    const isDuplicate = scenarios.some(
      (scenario, idx) => scenario.name === currentName && idx !== index
    );
  
    if (isDuplicate) {
      alert("This name already exists. Please choose a unique name.");
      // Revert to the previous valid name
      setScenarios((prevScenarios) => {
        const updatedScenarios = [...prevScenarios];
        updatedScenarios[index].name = `Scenario ${index + 1}`; // Default fallback name
        return updatedScenarios;
      });
    }
  };

  // Function to handle updating scenario description
  const handleScenarioChange = (index, description) => {
    const updatedScenarios = scenarios.map((scenario, i) =>
      i === index ? { ...scenario, description } : scenario
    );
    setScenarios(updatedScenarios);
  
    // Update data object with updated scenarios
    /*
    setData(prevData => ({
      ...prevData,
      actions: updatedScenarios,
    }));
    */

    setData(prevData => ({
      ...prevData,
      actions: {
        ...prevData.description,
        value: updatedScenarios, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.description.version + 1 // Increment the version
      }
    }));
  };

  // Function to remove a scenario
  /*
  const removeScenario0 = (index) => {

    // Ask for confirmation before removing a scenario
    if (!window.confirm('Are you sure you want to remove this scenario?')) {
      return;
    }

    const updatedScenarios = [...scenarios];
    updatedScenarios.splice(index, 1);
    setScenarios(updatedScenarios);

    const updatedConditions = [...conditions];
    updatedConditions.splice(index, 1);
    setConditions(updatedConditions);    
  
    // Update data object after removing a scenario
    setData(prevData => ({
      ...prevData,
      actions: {
        ...prevData.description,
        value: updatedScenarios, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.description.version + 1 // Increment the version
      },
    }));
  };
  */

  // Function to remove a scenario
  const removeScenario = (index) => {
    // Ask for confirmation before removing a scenario
    if (!window.confirm('Are you sure you want to remove this scenario?')) {
      return;
    }

    // Remove the scenario at the specified index
    const updatedScenarios = [...scenarios];
    updatedScenarios.splice(index, 1);
    setScenarios(updatedScenarios);

    // Remove the corresponding conditions for the scenario
    const updatedConditions = [...conditions];
    updatedConditions.splice(index, 1);
    setConditions(updatedConditions);

    // Update the data object to reflect the changes
    setData((prevData) => ({
      ...prevData,
      actions: {
        ...prevData.actions,
        value: updatedScenarios, // Update the scenarios in the data object
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDate(),
        updated_by:
          data?.anonymous_id?.value !== ''
            ? data?.anonymous_id?.value
            : UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.actions.version + 1,
      },
      conditions: {
        ...prevData.conditions,
        value: updatedConditions, // Update the conditions in the data object
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDate(),
        updated_by:
          data?.anonymous_id?.value !== ''
            ? data?.anonymous_id?.value
            : UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.conditions.version + 1,
      },
    }));
  };

  const addCondition = (scenarioIndex) => {
    const newConditions = [...conditions];
    if (!newConditions[scenarioIndex]) {
      newConditions[scenarioIndex] = { preConditions: '', postConditions: '', constraints: '', assumptions: '' };
    }
  
    const newKey = `newCondition${Object.keys(newConditions[scenarioIndex]).length}`;
    newConditions[scenarioIndex][newKey] = '';
  
    setConditions(newConditions);
  
    // Update data object with new conditions
    /*
    setData(prevData => ({
      ...prevData,
      conditions: newConditions,
    }));
    */
    setData(prevData => ({
      ...prevData,
      conditions: {
        ...prevData.conditions,
        value: newConditions, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1 // Increment the version
      }
    }));
  };
  
  // Function to remove a condition
  const removeCondition = (scenarioIndex, key) => {
    const updatedConditions = [...conditions];
    delete updatedConditions[scenarioIndex][key];
    setConditions(updatedConditions);
  
    // Update data object after removing a condition
    /*
    setData(prevData => ({
      ...prevData,
      conditions: updatedConditions,
    }));
    */
    setData(prevData => ({
      ...prevData,
      conditions: {
        ...prevData.conditions,
        value: updatedConditions, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1 // Increment the version
      }
    }));
  };

  // Function to handle updating condition type, description, and actor
  const handleConditionChange = (scenarioIndex, conditionKey, value) => {

    //console.log('scenarioIndex:', scenarioIndex);
    //console.log('conditionKey:', conditionKey);
    //console.log('value:', value);

    setConditions(prevConditions => {
      const newConditions = [...prevConditions];
      newConditions[scenarioIndex][conditionKey] = value;
      return newConditions;
    });
  
    // Update data object with updated conditions
    /*
    setData(prevData => ({
      ...prevData,
      conditions: prevData.conditions.map((scenario, i) =>
        i === scenarioIndex ? { ...scenario, [conditionKey]: value } : scenario
      ),
    }));
    */
    setData(prevData => ({
      ...prevData,
      conditions: {
        ...prevData.conditions,
        value: prevData.conditions.value.map((scenario, i) =>
          i === scenarioIndex ? { ...scenario, [conditionKey]: value } : scenario
        ),
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: data?.anonymous_id?.value !== '' ? data?.anonymous_id?.value : UserInformation().displayName, // only update if the user is not anonymous // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1 // Increment the version
      }
    }));
  };

  // Open the help modal
  const handleHelp = () => {
    showHelpModal(true);
  }

  // Toggle view state
  const handleAccordionToggle = (key) => {
    setExpandedKeys((prevExpandedKeys) => {
      if (prevExpandedKeys.includes(key)) {
        // Collapse the clicked accordion
        return prevExpandedKeys.filter((k) => k !== key);
      } else {
        // Expand the clicked accordion
        return [...prevExpandedKeys, key];
      }
    });
  };  

  const handleExpandCollapseAll = () => {
    if (expandedKeys.length === data.actions.value.length) {
      setExpandedKeys([]); // Collapse all
    } else {
      setExpandedKeys(data.actions.value.map((_, index) => index.toString())); // Expand all
    }
  };

  return (
    <div>
      <Row>
        {/* Headline and Toolbar */}
        <Col md={3}>
            <div>
                <h3 className="text-start">Define Scenarios</h3>
                <h6 className="text-start">Look up, adjust or add manually</h6>
            </div>
        </Col>
        {/* Toolbar */}
        <Col md={9} className="d-flex justify-content-end align-items-center">
            <OverlayTrigger placement="top" overlay={<Tooltip>Search scenario</Tooltip>}>
                <Form hidden>
                    <Form.Group>
                        <Form.Control
                            style={{ maxWidth: '200px' }}
                            // size='sm'
                            type="text"
                            placeholder="Search scenario"
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    // handleSearch(e);
                                }
                            }}
                        />
                    </Form.Group>
                </Form>
            </OverlayTrigger>   
            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }} hidden></div>

            <OverlayTrigger placement="top" overlay={<Tooltip>Add a new scenario, which sum represents the use case as a whole.</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleAdd}>
                    <i className="bi bi-plus"></i> Add a Scenario
                </Button>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip>Quickly add a new scenario bundle all at once.</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleQuickAdd}>
                  <i className="bi bi-journal-plus"></i> Add multiple
                </Button>
            </OverlayTrigger>

            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>

            <OverlayTrigger placement="top" overlay={<Tooltip>Expand or collapse scenarios.</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleExpandCollapseAll}>
                    {expandedKeys.length === data.actions.value.length ? 
                      <>
                      <i className="bi bi-arrows-collapse"></i> Collapse All
                      </>
                      :
                      <>
                      <i className="bi bi-arrows-expand"></i> Expand All
                      </>
                    }
                </Button>
            </OverlayTrigger>

            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>

            <OverlayTrigger placement="top" overlay={<Tooltip>Help: What is a scenario.</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleHelp}>
                    <i className="bi bi-question-circle"></i>
                </Button>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip>Select a scenario first. Click this button to edit it.</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled hidden>
                    <i className="bi bi-pencil"></i>
                </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Select a scenario first. Click this button to delete it.</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled hidden>
                    <i className="bi bi-trash"></i>
                </Button>
            </OverlayTrigger>                                                       
            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }} hidden></div>
            <OverlayTrigger placement="top" overlay={<Tooltip>Download actor landscape</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled hidden>
                    <i className="bi bi-download"></i>
                </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Upload actor landscape</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled hidden>
                    <i className="bi bi-upload"></i>
                </Button>
            </OverlayTrigger>                                    
        </Col>
      </Row>
      <Row style={ { marginTop:'15px' } }>
        {/* Define use case scenarios */}
        <Col md={2}></Col>
        {(data.actions.value.length === 0) ? (
          <>
          <Row align='center' className="justify-content-center">
            <Col>
              <img src={Papierboot} className="img-fluid" alt="Responsive" style={{ width: '20%', height: 'auto' }}/> 
              <OverlayTrigger placement="top" overlay={<Tooltip>No scenario added. Add one manually.</Tooltip>}>
              <Button variant="primary" style={{ marginRight: '10px' }} onClick={handleAdd}>
                <i className="bi bi-add"></i> Add a scenario
              </Button>
              </ OverlayTrigger>
            </Col>
          </Row>
          </>
        ) : (
          <>
          <Col md={8} style={{ textAlign: 'left' }}>
            {/* Render scenario and condition inputs */}
            <Accordion id="accordionExample" activeKey={expandedKeys}>
              {data.actions.value.map((scenario, index) => (
                <Accordion.Item 
                  eventKey={index.toString()} 
                  key={index}
                  onClick={(e) => {
                    // Allow toggling only when clicking the wrapper, not child elements
                    if (e.target.tagName !== "TEXTAREA" ) {
                      handleAccordionToggle(index.toString());
                    }
                  }}
                  >
                  <Accordion.Header>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <span>Scenario {index + 1}:</span>
                      <Form.Control
                        type="text"
                        value={scenario.name}
                        placeholder="Edit scenario name"
                        onChange={(e) => handleScenarioNameChange(index, e.target.value)}
                        onBlur={() => validateScenarioName(index)} // Validate on blur to check for duplicates
                        className="form-control-sm"
                        style={{ 
                          width: "auto", 
                          minWidth:'500px', 
                          display: "inline-block", 
                          fontSize: "1rem", 
                          fontWeight: "bold",
                          border: "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                      />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <textarea
                      className="form-control mb-3"
                      value={scenario.description}
                      onChange={(e) => handleScenarioChange(index, e.target.value)}
                      rows={5}
                      placeholder={`Enter a scenario description...`}
                    />

                    <Button variant="primary" style={{ marginTop: '10px' }} onClick={() => addCondition(index)} hidden>
                      Add Condition, Constraint, or Assumption
                    </Button>

                    {/* Render conditions 
                    <div className="row" style={{ marginTop: '10px' }}>
                      {data.conditions.value[index] &&
                        Object.entries(data.conditions.value[index]).map(([key, value], conIndex) =>
                          key !== 'scenario' && (
                            <div key={`${index}-${conIndex}`} className="col-md-6 mb-3">
                              <Form.Group className="d-flex justify-content-between">
                                <Form.Label>Condition {conIndex + 1}</Form.Label>
                                <Button variant="close" onClick={() => removeCondition(index, key)} hidden />
                              </Form.Group>
                              <Form.Group>
                                <Form.Select
                                  disabled
                                  name="type"
                                  value={key}
                                  // onChange={(e) => handleConditionChange(index, conIndex, e.target.value)}
                                >
                                  <option value="preConditions">Pre-condition(s)</option>
                                  <option value="postConditions">Post-condition(s)</option>
                                  <option value="constraints">Constraint(s)</option>
                                  <option value="assumptions">Assumption(s)</option>
                                </Form.Select>
                              </Form.Group>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                name={key}
                                placeholder="Enter condition description..."
                                value={value}
                                onChange={(e) => handleConditionChange(index, key, e.target.value)}
                                className="mb-2"
                              />
                            </div>
                          )
                        )}
                    </div>
                    */}
                    {/* Render conditions */}
                    <div className="row" style={{ marginTop: '10px' }}>
                      {data.conditions.value[index] &&
                        (() => {
                          let counter = 1; // Initialize the counter at 1 for each scenario

                          return Object.entries(data.conditions.value[index]).map(([key, value]) => {
                            // Render only for valid condition types
                            const conditionTypes = {
                              preConditions: 'Pre-condition(s)',
                              postConditions: 'Post-condition(s)',
                              constraints: 'Constraint(s)',
                              assumptions: 'Assumption(s)',
                            };

                            if (!conditionTypes[key]) return null;

                            const conditionNumber = counter++; // Increment the counter for each valid condition

                            return (
                              <div key={`${index}-${key}`} className="col-md-6 mb-3">
                                {/* Render Condition Label */}
                                <Form.Group className="d-flex justify-content-between align-items-center">
                                  <Form.Label className="mb-0">
                                    Condition {conditionNumber} -{' '}
                                    <span style={{ fontWeight: 'bold' }}>{conditionTypes[key]}</span>
                                  </Form.Label>
                                  <Button
                                    variant="close"
                                    onClick={() => removeCondition(index, key)}
                                    style={{ marginLeft: '10px' }}
                                    hidden
                                  />
                                </Form.Group>

                                {/* Condition Description Textarea */}
                                <Form.Control
                                  as="textarea"
                                  rows={2}
                                  name={key}
                                  placeholder={`Enter ${conditionTypes[key].toLowerCase()}...`}
                                  value={value}
                                  onChange={(e) => handleConditionChange(index, key, e.target.value)}
                                  className="mb-2"
                                />
                              </div>
                            );
                          });
                        })()}
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button className='btn-sm' variant="outline-danger" onClick={() => removeScenario(index)}>
                        Remove Scenario
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
          </>
        )}
        {/* Define use case conditions, constraints, and assumptions */}
        <Col md={2} style={{ textAlign: 'left' }}>
        </Col>
      </Row>

      {/* Add scenario modal */}
      <AddScenarioModal 
        show={showAdd} 
        handleClose={() => showAddModal(false)} 
        scenarioName={scenarioName}
        setScenarioName={setScenarioName}
        scenarioDescription={scenarioDescription}
        setScenarioDescription={setScenarioDescription}
        handleAddScenario={handleAddScenario} />

      {/* Quick add scenario modal */}
      <AddQuickScenarioModal 
        show={showQuickAdd} 
        data={data}
        handleClose={() => showQuickAddModal(false)} 
        handleAddMultipleScenarios={handleAddMultipleScenarios} />

      {/* Help modal */}
      <HelpModal show={showHelp} handleClose={() => showHelpModal(false)} />        

    </div>
  );
};

export default Action;
