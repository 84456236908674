/**
 * Defines the draggable item elements
 * 
 * Description: This form contains the components of the use case
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-26
 * 
 */

import { Badge, Button } from 'react-bootstrap';
import { useDrag } from 'react-dnd';

// Define the draggable item
const DraggableItem = ({ item }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'COMPONENT',
        item,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <Badge

          className="bg-light text-dark"
          ref={drag}
          style={{
              opacity: isDragging ? 0.5 : 1,
              cursor: 'cursor', //'move',
              display: 'inline-block',
              position: 'relative',
              marginLeft: item.subconcepts && item.subconcepts.length > 0 ? '10px' : '22px',
              fontWeight: item.subconcepts && item.subconcepts.length > 0 ? 'bold' : 'normal',
          }}
        >
            {item.title}
            {item.subconcepts && item.subconcepts.length > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                    {item.subconcepts.length}
                </span>
            )}
        </Badge>
    );
};

// Define the dragged item
const DraggedItem = ({ item, sectionName, handleDeleteElement }) => {

    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'COMPONENT',
      item,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));
  
    return (
      <div style={{ display: 'inline-block', position: 'relative', margin: '5px' }}>
        <Badge
          className="bg-light text-dark"
          ref={drag}
          style={{
            cursor: 'move',
            opacity: isDragging ? 0.5 : 1,
            display: 'inline-block',
            marginLeft: '10px',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            maxWidth: '200px', 
          }}
        >
            {item.title}
            <Button
                variant="link"
                size="sm"
                onClick={() => handleDeleteElement(sectionName, item.title)}
                style={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    padding: '0px',
                    fontSize: '1rem',
                    color: 'red',
                } }
                >
                <i className="bi bi-x"></i>
            </Button>
        </Badge>
      </div>
    );
};

// Render the dropped items
const RenderDroppedItems = (items, sectionName, handleDeleteElement) => {

  return items?.map((item, index) => (
    <DraggedItem key={crypto.randomUUID()} item={{ title: item }} sectionName={sectionName} handleDeleteElement={handleDeleteElement}/>
  ));

};

export { DraggableItem, DraggedItem, RenderDroppedItems };