/**
 * Transcription Service Component
 *
 * Description: This component is responsible for the transcription service.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-12-23
 */

import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Spinner, Alert, Button, ProgressBar, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Document, Packer, Paragraph, Table, TableCell, TableRow, BorderStyle, AlignmentType, WidthType } from 'docx';

import CreatableSelect from 'react-select/creatable';

import OpenAI from 'openai';
import { getOpenAIKey } from '../components/db_mgmt/db_handling_use_cases/read';
//import { transcribeWithWhisper } from '../components/study_handling/study_interview/study_transcription';

/*
const transcribeWithWhisper = async (openaiInstance, audioBlob) => {
  try {
      // Ensure the audioBlob is correctly converted to a `File` object
      const audioFile = new File([audioBlob], 'audio.mp3', { type: 'audio/mp3' });

      // Use the OpenAI SDK to create the transcription
      const transcription = await openaiInstance.audio.transcriptions.create({
          model: 'whisper-1',
          file: audioFile,
          response_format: 'verbose_json',
          timestamps: true,
      });

      return transcription; //.text; // Extract transcription text

  } catch (error) {
      console.error('Error during Whisper transcription:', error);
      return 'Transcription failed';
  }
};
*/

const transcribeWithWhisper = async (openaiInstance, audioBlob) => {
  try {
    // Determine the file type from the Blob
    const fileType = audioBlob.type || 'audio/mpeg'; // Default to mp3 if type is not provided

    // Generate the file name dynamically based on the type
    const fileExtension = fileType.split('/')[1] || 'mp3';
    const audioFile = new File([audioBlob], `audio.${fileExtension}`, { type: fileType });

    // Use the OpenAI SDK to create the transcription
    const transcription = await openaiInstance.audio.transcriptions.create({
      model: 'whisper-1',
      file: audioFile,
      response_format: 'verbose_json',
      timestamps: true,
    });

    return transcription; // Return the full transcription object for more flexibility
  } catch (error) {
    console.error('Error during Whisper transcription:', error);
    return { text: 'Transcription failed', segments: [] }; // Return a fallback object
  }
};

function TranscriptionDisplay({ transcription }) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Display the first 100 characters (or adjust this length as needed)
  const previewText = transcription.text.slice(0, 100);

  return (
    <div>
      <h4 style={{ marginTop: '20px', marginBottom: '10px' }}>
        Full Transcription:
      </h4>
      <p style={{ display: 'inline-flex', alignItems: 'center' }}>
        {transcription.text.length > 100
          ? `${previewText}... `
          : transcription.text}
        {transcription.text.length > 100 && (
          <Button
            variant="link"
            onClick={handleShowModal}
            style={{
              padding: 0,
              textDecoration: 'underline',
              fontSize: 'inherit', // Match the font size of the surrounding text
              verticalAlign: 'baseline', // Align with the text
            }}
          >
            look up entire transcription
          </Button>
        )}
      </p>

      {/* Modal for full transcription */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Full Transcription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{transcription.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const SpeakerSelect = ({ segment, index, transcription, setTranscription, options, setOptions }) => {

  const handleChange = (newValue) => {
    const updatedSegments = [...transcription.segments];
    updatedSegments[index].speaker = newValue ? newValue.value : '';
    setTranscription({ ...transcription, segments: updatedSegments });

    // Add new custom option to the options list if it doesn't already exist
    if (newValue && !options.some(option => option.value === newValue.value)) {
      setOptions([...options, newValue]);
    }
  };

  return (
    <CreatableSelect
      isClearable
      value={segment.speaker ? { value: segment.speaker, label: segment.speaker } : null}
      onChange={handleChange}
      options={options}
      placeholder="Select / add speaker"
    />
  );
};

export default function Transcription() {

  const [transcription, setTranscription] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [openai, setOpenai] = useState(null);

  const [audioFile, setAudioFile] = useState(null);

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [options, setOptions] = useState([
    { value: 'B', label: 'B' },
    { value: 'I', label: 'I' },
    { value: 'Interviewer', label: 'Interviewer' },
    { value: 'Speaker 1', label: 'Speaker 1' },
    { value: 'Speaker 2', label: 'Speaker 2' },
    { value: 'Speaker 3', label: 'Speaker 3' },
  ]);

  // Initialize the OpenAI API
  useEffect(() => {
      const initializeOpenAI = async () => {
          try {
              const apiKey = await getOpenAIKey();
              const openaiInstance = new OpenAI({
                  apiKey: apiKey,
                  dangerouslyAllowBrowser: true,
              });
              setOpenai(openaiInstance);
          } catch (error) {
              console.error('Error fetching API key:', error);
          }
      };

      initializeOpenAI();
  }, []);

  /*
  const handleFileUpload0 = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    setError(null);
    setIsLoading(true);
  
    try {
      const blob = new Blob([file], { type: file.type });
      
      // Transcribe audio using Whisper
      const transcriptionResult = await transcribeWithWhisper(openai, blob);
      console.log('Transcription result:', transcriptionResult);
  
      // Extract segments with timestamps
      const segments = transcriptionResult.segments || [];
      console.log('Segments:', segments);
      setTranscription({
        text: transcriptionResult.text,
        segments: segments.map((segment) => ({
          start: segment.start,
          end: segment.end,
          text: segment.text,
          speaker: segment.speaker || 'Unknown', // Speaker (if available)
        })),
      });
    } catch (err) {
      setError('Failed to transcribe audio. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };  
  */

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setAudioFile(URL.createObjectURL(file));
    setError(null);
    setIsLoading(true);

    try {
      const blob = new Blob([file], { type: file.type });
      
      // Transcribe audio using Whisper
      const transcriptionResult = await transcribeWithWhisper(openai, blob);
      //console.log('Transcription result:', transcriptionResult);

      // Extract segments with timestamps
      const segments = transcriptionResult.segments || [];
      setTranscription({
        text: transcriptionResult.text,
        segments: segments.map((segment) => ({
          start: segment.start,
          end: segment.end,
          text: segment.text,
          speaker: segment.speaker || '',
        })),
      });
    } catch (err) {
      setError('Failed to transcribe audio. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const changePlaybackRate = (rate) => {
    if (audioRef.current) {
      audioRef.current.playbackRate = rate;
    }
  };

  const goBack10Seconds = () => {
    if (audioRef.current) {
      const newTime = Math.max(audioRef.current.currentTime - 10, 0); // Ensure time does not go below 0
      audioRef.current.currentTime = newTime; // Update the audio time
      setCurrentTime(newTime); // Sync with progress bar
    }
  };

  const goForward10Seconds = () => {
    if (audioRef.current) {
      const newTime = Math.min(audioRef.current.currentTime + 10, audioRef.current.duration); // Ensure time does not exceed duration
      audioRef.current.currentTime = newTime; // Update the audio time
      setCurrentTime(newTime); // Sync with progress bar
    }
  };

  const resetAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0; // Reset audio to the beginning
      setCurrentTime(0); // Reset progress bar
      setIsPlaying(false); // Set play state to false
      audioRef.current.pause(); // Ensure the audio stops playing
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const exportToTextFile = () => {
    if (!transcription) return;

    let textContent = `Full Transcription:\n${transcription.text}\n\nSegments:\n`;
    transcription.segments.forEach((segment, index) => {
      textContent += `Segment ${index + 1}:\n`;
      textContent += `Timestamp: ${segment.start.toFixed(2)} - ${segment.end.toFixed(2)} seconds\n`;
      textContent += `Speaker: ${segment.speaker || 'Unknown'}\n`;
      textContent += `Text: ${segment.text}\n\n`;
    });

    const blob = new Blob([textContent], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'transcription.txt';
    link.click();
  };

  const exportToTable = () => {
    if (!transcription) return;
  
    // Add a single header
    let textContent = `Timestamp, Speaker, Transcription\n`;
  
    // Add each segment in the required format
    transcription.segments.forEach((segment) => {
      const timestamp = `${segment.start.toFixed(2)} - ${segment.end.toFixed(2)}`;
      const speaker = segment.speaker || 'Unknown';
      const text = segment.text;
      textContent += `${timestamp}, ${speaker}, ${text}\n`;
    });
  
    // Create and download the text file
    const blob = new Blob([textContent], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'transcription.txt';
    link.click();
  };

  const exportToCSVFile = () => {
    if (!transcription) return;
  
    // Add a single header
    let csvContent = `Timestamp;Speaker;Transcription\n`;
  
    // Add each segment in the required format
    transcription.segments.forEach((segment) => {
      const timestamp = `${segment.start.toFixed(2)} - ${segment.end.toFixed(2)}`;
      const speaker = segment.speaker || 'Unknown';
      const text = segment.text.replace(/;/g, ','); // Replace semicolons in text to avoid breaking CSV format
      csvContent += `${timestamp};${speaker};${text}\n`;
    });
  
    // Create and download the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'transcription.csv';
    link.click();
  };  

  const exportToWordFile = () => {
    if (!transcription) return;
  
    // Create metadata section
    const metadataParagraphs = [
      new Paragraph({
        text: "Metadata:",
        heading: "Heading2",
      }),
      new Paragraph({ text: "\n" }), // Spacer paragraph
      new Paragraph({
        text: `Duration: ${duration.toFixed(2)} seconds`,
        bullet: { level: 0 },
      }),
      new Paragraph({
        text: `Segments: ${transcription.segments.length}`,
        bullet: { level: 0 },
      }),
      new Paragraph({
        text: `Speakers: ${new Set(transcription.segments.map((segment) => segment.speaker)).size}`,
        bullet: { level: 0 },
      }),
      new Paragraph({
        text: `Transcription Length: ${transcription.text.length} characters`,
        bullet: { level: 0 },
      }),
      new Paragraph({ text: "\n" }), // Spacer paragraph
    ];
  
    // Create header row
    const headerRow = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: "Timestamp", bold: true })],
          shading: { fill: "CCCCCC" },
          borders: {
            top: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
            bottom: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
            left: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
            right: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
          },
        }),
        new TableCell({
          children: [new Paragraph({ text: "Speaker", bold: true })],
          shading: { fill: "CCCCCC" },
          borders: {
            top: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
            bottom: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
            left: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
            right: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
          },
        }),
        new TableCell({
          children: [new Paragraph({ text: "Transcription", bold: true })],
          shading: { fill: "CCCCCC" },
          borders: {
            top: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
            bottom: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
            left: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
            right: { style: BorderStyle.SINGLE, size: 2, color: "000000" },
          },
        }),
      ],
    });
  
    // Create rows for the table
    const tableRows = [
      headerRow,
      ...transcription.segments.map((segment) =>
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: `${segment.start.toFixed(2)} - ${segment.end.toFixed(2)}`,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: segment.speaker || "Unknown",
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
            new TableCell({
              children: [new Paragraph(segment.text)],
            }),
          ],
        })
      ),
    ];
  
    // Create the document
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: "UCM Powered Full Transcription Service",
              heading: "Heading1",
            }),
            ...metadataParagraphs, // Insert metadata before the table
            new Table({
              width: { size: 100, type: WidthType.PERCENTAGE },
              rows: tableRows,
            }),
            new Paragraph({
              text: "\nThis document was generated using the UCM transcription service powered by MGA. No commercial use is permitted without prior authorization from Marc Augusto.",
              alignment: AlignmentType.CENTER,
              spacing: { before: 400 },
            }),
          ],
        },
      ],
    });
  
    // Export the document
    Packer.toBlob(doc).then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "transcription.docx";
      link.click();
    });
  };  
  
  return (
    <Container fluid>
      {/* Introduction */}    
      <Row align="center">
        <h1 className="mt-5 mb-3">
          <b>UCM Transcription Service</b>
        </h1>
        <Col md={8} className="offset-md-2">
        <p style={{ maxWidth: '700px', margin: '0 auto' }}>
            Easily transcribe audio files into text with detailed timestamps and speaker identification.
          </p>
        <p style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '10px' }}>
          Upload an audio file to transcribe it into text with timestamps and editable speaker names.
        </p>
        </Col>
      </Row>
      {/* File Upload Section */}
      <Row className="justify-content-center mt-4">
        <Col>
          <Form style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
            <Form.Group>
              <Form.Label>Upload an audio file:</Form.Label>
              <Form.Control type="file" accept="audio/*" onChange={handleFileUpload} />
            </Form.Group>
          </Form>
          {/* Loading Spinner */}
          {isLoading && (
            <div className="text-center mt-3">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Processing...</span>
              </Spinner>
              <p className="mt-2">Processing your file...</p>
            </div>
          )}
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {/* Error Message */}
          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
          {audioFile && (
            <div style={{ marginTop: '20px', textAlign: 'left' }}>
              <audio
                ref={audioRef}
                src={audioFile}
                controls={false}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
              />
              <div className="d-flex align-items-center justify-content-end" style={ { maxWidth: '1000px', margin: '0 auto', marginTop:'30px', marginBottom: '10px' } }>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={togglePlayPause}
                  style={{ marginLeft: '10px' }}
                >
                  {isPlaying ? <i className="bi bi-pause-fill"></i> : <i className="bi bi-play-fill"></i>}
                </Button>
                <OverlayTrigger overlay={<Tooltip id="tooltip-playback-rate">Slow down playback rate</Tooltip>}>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  style={{ marginLeft: '10px' }}
                  onClick={() => changePlaybackRate(0.75)}
                >
                  <i className="bi bi-dash-circle"></i>
                </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-playback-rate">Reset playback rate</Tooltip>}>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  style={{ marginLeft: '10px' }}
                  onClick={() => changePlaybackRate(1.0)}
                >
                  <i className="bi bi-circle"></i>
                </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-playback-rate">Speed up playback rate</Tooltip>}>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  style={{ marginLeft: '10px' }}
                  onClick={() => changePlaybackRate(1.5)}
                >
                  <i className="bi bi-plus-circle"></i>
                </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-playback-rate">Go back 10 seconds</Tooltip>}>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  style={{ marginLeft: '10px' }}
                  onClick={goBack10Seconds}
                >
                  <i className="bi bi-skip-backward"></i>
                </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-playback-rate">Go forward 10 seconds</Tooltip>}>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  style={{ marginLeft: '10px' }}
                  onClick={goForward10Seconds}
                >
                  <i className="bi bi-skip-forward"></i>
                </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-playback-rate">Reset audio</Tooltip>}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  style={{ marginLeft: '10px' }}
                  onClick={resetAudio}
                >
                  <i className="bi bi-arrow-counterclockwise"></i>
                </Button>
                </OverlayTrigger>
              </div>
              <ProgressBar
                now={(currentTime / duration) * 100}
                label={`${Math.round(currentTime)} / ${Math.round(duration)} sec`}
                style={{ marginTop: '10px', maxWidth: '1000px', margin: '0 auto' }}
              />
            </div>
          )}
          {transcription && (
            <div style={{ marginTop: '15px', textAlign: 'left', maxWidth: '1000px', margin: '0 auto', marginBottom: '10px' }}>
              <TranscriptionDisplay transcription={transcription} />
              {/* Metadata */}
              <div style={{ marginTop: '15px' }}>
                <h4>Metadata:</h4>
                <div className="d-flex align-items-center">
                  <p style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                    <i className="bi bi-clock" style={{ marginRight: '5px' }}> 
                    <strong>Duration:</strong> {' '}  {duration.toFixed(2)} seconds
                    </i>
                  </p>
                  <p style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                    <i className="bi bi-list" style={{ marginRight: '5px' }}> 
                    <strong>Segments: </strong> {transcription.segments.length}
                    </i>
                  </p>
                  <p style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                    <i className="bi bi-people" style={{ marginRight: '5px' }}> 
                    <strong>Speakers: </strong> {new Set(transcription.segments.map((segment) => segment.speaker)).size}
                    </i>
                  </p>
                  <p style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                    <i className="bi bi-file-text" style={{ marginRight: '5px' }}> 
                    <strong>Transcription: </strong> {transcription.text.length} characters
                    </i>
                  </p>
                  <p style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                    <i className="bi bi-file-earmark-text" style={{ marginRight: '5px' }}> 
                    <strong>Export: </strong> <i className="bi bi-file-earmark-text"></i> <i className="bi bi-table"></i> <i className="bi bi-file-earmark-spreadsheet"></i> <i className="bi bi-file-word"></i>
                    </i>
                  </p>
                </div>
              </div>
              {/* Segments */}
              <h4 style={{ marginTop:'15px'}}>Segments:</h4>
              <table className="table table-bordered" style={{ marginTop: '20px' }}>
                <thead>
                  <tr>
                    <th><i className="bi bi-clock" style={{ marginRight: '5px' }}></i> Timestamp</th>
                    <th><i className="bi bi-people" style={{ marginRight: '5px' }}></i> Speaker</th>
                    <th><i className="bi bi-file-text" style={{ marginRight: '5px' }}></i> Transcription</th>
                  </tr>
                </thead>
                <tbody>
                  {transcription.segments.map((segment, index) => (
                    <tr key={index}>
                      <td>
                        {segment.start.toFixed(2)} - {segment.end.toFixed(2)} seconds
                      </td>
                      <td>
                      <SpeakerSelect
                        segment={segment}
                        index={index}
                        transcription={transcription}
                        setTranscription={setTranscription}
                        options={options}
                        setOptions={setOptions}
                      />
                      </td>
                      <td style={{ minWidth: '500px' }}>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={segment.text.trimStart()} // Removes any leading whitespace
                          onChange={(e) => {
                            const updatedSegments = [...transcription.segments];
                            updatedSegments[index].text = e.target.value;
                            setTranscription({ ...transcription, segments: updatedSegments });
                          }}
                          placeholder="Edit text..."
                          style={{
                            border: 'none',
                            resize: 'none',
                            boxSizing: 'border-box',
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-end">
                <OverlayTrigger overlay={<Tooltip id="tooltip-export-table">Export to table</Tooltip>}>
                <Button
                  variant="success"
                  style={{ marginTop: '20px' }}
                  onClick={exportToTextFile}
                >
                  <i className="bi bi-file-earmark-text"></i> Export to Text File
                </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-export-table">Export to table</Tooltip>}>
                <Button
                  variant="success"
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                  onClick={exportToTable}
                >
                  <i className="bi bi-table"></i> Export to Table
                </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-export-table">Export to table</Tooltip>}>
                <Button
                  variant="success"
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                  onClick={exportToCSVFile}
                >
                  <i className="bi bi-file-earmark-spreadsheet"></i> Export to CSV File
                </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-export-table">Export to table</Tooltip>}>
                <Button
                  variant="success"
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                  onClick={exportToWordFile}
                >
                  <i className="bi bi-file-word"></i> Export to Word File
                </Button>
                </OverlayTrigger>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}


export { default as Transcription } from './Transcription';