/**
 * Modal Edit Edge
 *
 * Description: Modal to edit an edge between two nodes
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-16
 * 
 */

import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditEdgeModal = ({ show, onHide, nodes, edge, onEditEdge }) => {
    const [fromNodeId, setFromNodeId] = useState('');
    const [toNodeId, setToNodeId] = useState('');

    useEffect(() => {
        if (show && edge) {
            setFromNodeId(edge.from);
            setToNodeId(edge.to);
        }
    }, [show, edge]);

    const handleEditEdge = () => {
        if (fromNodeId && toNodeId) {
            onEditEdge({ ...edge, from: fromNodeId, to: toNodeId });
            onHide();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            handleEditEdge();
        }
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Edge</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formFromNode">
                        <Form.Label>Source Actor</Form.Label>
                        <Form.Control
                            as="select"
                            value={fromNodeId}
                            onChange={(e) => setFromNodeId(e.target.value)}
                            onKeyDown={handleKeyDown}
                        >
                            <option value="">Select node...</option>
                            {nodes.map((node) => (
                                <option key={node.id} value={node.id}>
                                    {node.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formToNode">
                        <Form.Label>Target Actor</Form.Label>
                        <Form.Control
                            as="select"
                            value={toNodeId}
                            onChange={(e) => setToNodeId(e.target.value)}
                            onKeyDown={handleKeyDown}
                        >
                            <option value="">Select node...</option>
                            {nodes.map((node) => (
                                <option key={node.id} value={node.id}>
                                    {node.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleEditEdge}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditEdgeModal;